import { useState, useEffect, useRef } from "react";
import Checkbox from "../forms/Checkbox";
import CalendarPicker from "../forms/CalendarPicker";
import TimePicker from "../picker/TimePicker";
import Button from "../buttons/Button";
import moment from "moment";

import "./changeTime.scss";

export default function ChangeTime(props) {
  useEffect(() => {
    let meetingTime =
      moment(new Date(props.selected)).format("YYYY-MM-DD") +
      " " +
      props.startTime;

    props.setMeetingTime(meetingTime);

    let meetingEnd;
    if (props.endTime != "") {
      meetingEnd =
        moment(new Date(props.selected)).format("YYYY-MM-DD") +
        " " +
        moment(props.endTime).format("HH:mm");

      props.setMeetingEnd(meetingEnd);
    }
  }, [props.endTime, props.startTime, props.selected]);
  let date = props.MeetingTime;
  return (
    <div>
      <div className="changeTimeContainer">
        <div className="CurrentTimeContainer">
          <span>
            {"Your old meeting time: " +
              moment(date).format("DD.MM.YYYY HH:mm")}
          </span>
        </div>
        <div style={{ margin: "1%", opacity: props.isOpenDated ? 0.25 : 1 }}>
          <div>
            <CalendarPicker
              selected={props.selected}
              setSelected={props.setSelected}
            />
          </div>
          <div className="timelineContainer">
            <h3>Start</h3>
            <TimePicker setTime={props.setStartTime} time={props.startTime} />

            <h3>End</h3>
            {props.meetingEnd === "" ? (
              <div onClick={() => props.setMeetingEnd("12")}>
                <span>open end</span>
              </div>
            ) : (
              <TimePicker setTime={props.setEndTime} time={props.endTime} />
            )}
          </div>
        </div>
      </div>
      <Button buttonClick={() => props.setChangeTime(false)} name={"Done"} />
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pull_status: []
};

export const pullstateSlice = createSlice({
	name: 'pullstate',
	initialState,
	reducers: {},
	extraReducers: {
		UpdateChatPullStatus: (state, action) => {
			let pull_status = JSON.parse(JSON.stringify(state.pull_status));
			let msg = action.payload.message;
			pull_status.forEach(status => {
				if (status.meeting_id === msg.meeting_id) {
					status.pull = false
				}
			});
			return {
				...state,
				pull_status: pull_status
			};
		},
		InsertChatPullStatus: (state, action) => {
			let pull_status = JSON.parse(JSON.stringify(state.pull_status));
			pull_status.push(action.payload.message);
			return {
				...state,
				pull_status: pull_status
			};
		},
		ResetChatPullStatus: (state, action) => {
			let msg = action.payload.message;
			if (msg === null) {
				return {
					pull_status: []
				};
			} else {
				let pull_status = [];
				msg.forEach(meeting => {
					pull_status.push({
						meeting_id: meeting.m_id,
						pull: true
					});
				});
				return {
					pull_status: pull_status
				};
			}
		}
	},
});

export const pullstate = state => state.pullstate;
export default pullstateSlice.reducer;
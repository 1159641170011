import { useState } from "react";

import Navbar from "../components/navbar/Navbar";
import CalendarContainer from "../components/calendar/CalendarContainer";
import CardContainer from "../components/cards/CardContainer";
import DetailsContainer from "../components/details/DetailsContainer";
import OverlayContainer from "../components/overlay/OverlayContainer";
import ChangeOverlayContainer from "../components/changeOverlay/ChangeOverlayContainer";
import { Mobile, Tablet, Desktop } from "../styles/Responsive";
import TabNav from "../components/tabnav/TabNav";
import MobileMenu from "../components/MobileMenu/MobileMenu";

export default function Meetings() {
  const [createMeetingModal, setCreateMeetingModal] = useState(false);
  const toggleMeetingModal = () => setCreateMeetingModal((value) => !value);
  const [changeMeetingModal, setChangeMeetingModal] = useState(false);
  const toggleChangeMeetingModal = () =>
    setChangeMeetingModal((value) => !value);
  const [currentMeeting, setCurrentMeeting] = useState("");
  const [showMeeting, setShowMeeting] = useState([]);
  const [detailedMeeting, setDetailedMeeting] = useState("");
  console.log("meeting ID for details: ", detailedMeeting);
  return (
    <>
      <OverlayContainer
        createMeetingModal={createMeetingModal}
        setCreateMeetingModal={setCreateMeetingModal}
        toggleMeetingModal={toggleMeetingModal}
      >
        <h1> Awesome modal </h1>
      </OverlayContainer>
      <ChangeOverlayContainer
        createMeetingModal={changeMeetingModal}
        setCreateMeetingModal={setChangeMeetingModal}
        toggleMeetingModal={toggleChangeMeetingModal}
        detailedMeeting={detailedMeeting}
      />
      <Mobile>
        <div className="m-container">
          <MobileMenu />
          <div className="m-content__grid meetings">
            <div className="m-card__container">
              <CardContainer />
            </div>
            <div className="m-details__container">
              <DetailsContainer />
            </div>
          </div>
          <TabNav />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-container">
          <MobileMenu />
          <div className="t-content__grid meetings">
            <div className="t-calendar__container">
              <CalendarContainer setShowMeeting={setShowMeeting} />
            </div>
            <div className="t-card__container">
              <CardContainer
                toggleMeetingModal={toggleMeetingModal}
                showMeeting={showMeeting}
              />
            </div>
            <div className="t-details__container">
              <DetailsContainer />
            </div>
          </div>
          <TabNav />
        </div>
      </Tablet>
      <Desktop>
        <div className="d-container">
          <div className="d-content__grid meetings">
            <Navbar />
            <div className="d-calendar__container">
              <CalendarContainer
                setShowMeeting={setShowMeeting}
                setDetailedMeeting={setDetailedMeeting}
              />
            </div>
            <div className="d-card__container">
              <CardContainer
                toggleMeetingModal={toggleMeetingModal}
                showMeeting={showMeeting}
                setDetailedMeeting={setDetailedMeeting}
              />
            </div>
            <div className="d-details__container">
              <DetailsContainer
                detailedMeeting={detailedMeeting}
                toggleChangeMeetingModal={toggleChangeMeetingModal}
              />
            </div>
          </div>
        </div>
      </Desktop>
    </>
  );
}

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AmazonCognitoIdentity,
} from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

function signUp(password, name, family_name, email, gender) {
  var attributeList = [];

  // var dataEmail = {
  // 	Name: 'email',
  // 	Value: email,
  // };
  var dataName = {
    Name: "given_name",
    Value: name,
  };
  var dataFamilyName = {
    Name: "family_name",
    Value: family_name,
  };
  var dataGender = {
    Name: "gender",
    Value: gender,
  };

  // var attributeEmail = new CognitoUserAttribute(dataEmail);
  var attributeName = new CognitoUserAttribute(dataName);
  var attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
  var attributeGender = new CognitoUserAttribute(dataGender);

  // attributeList.push(attributeEmail);
  attributeList.push(attributeName);
  attributeList.push(attributeFamilyName);
  attributeList.push(attributeGender);

  return new Promise((resolve, reject) =>
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
        return;
      }
      var cognitoUser = result.user;
      console.log("user name is " + cognitoUser.getUsername());
      resolve(result);
    })
  );
}

export default signUp;

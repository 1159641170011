import axios from "../../utils/customAxios";
import { createAwsClient } from "agnostic-aws-signature";
import Storage from "../../utils/localStorage";
// import {apiPath} from '../../utils/constants';
import { store } from "../store";

//

import { sha256 } from "js-sha256";
// import sha256 from "crypto-js/sha256";
// import { RequestSigner } from "aws4";

export async function SignHeaders(action, body) {
  const state = store.getState();
  const currentUser = state.currentUser;
  let bodyString = JSON.stringify(body);

  let contentSha = "";

  contentSha = sha256(bodyString);

  let SessionToken = currentUser.sessionToken;
  let SecretKey = currentUser.secretKey;
  let AccessKeyId = currentUser.accessKeyId;

  const awsClient = createAwsClient(AccessKeyId, SecretKey, SessionToken, {
    region: "eu-central-1",
    endpoint: process.env.REACT_APP_apiPath + action.api,
  });
  // console.log("apicheck: ", action.api);
  // console.log("awsclient: ", JSON.stringify(awsClient, null, 2));

  let options = {};
  if (action.method === "POST" || action.method === "post") {
    const signedRequest = await awsClient.signRequest({
      method: action.method,
      headers: {
        "Content-Type": "application/json",
        "X-Amz-Security-Token": SessionToken,
        "X-Amz-Content-Sha256": contentSha,
      },
      body: bodyString,
    });
    console.log("signed request: ", JSON.stringify(signedRequest, null, 2));

    options = {
      method: action.method,
      headers: signedRequest.headers,
      url: signedRequest.url,
      data: bodyString,
    };

    console.log("options that I created: ", options);
  } else if (action.method === "GET" || action.method === "get") {
    const signedRequest = await awsClient.signRequest({
      method: action.method,
      headers: {
        "X-Amz-Security-Token": SessionToken,
        "x-amz-content-sha256": contentSha,
      },
      // body: bodyString,
    });

    delete signedRequest.headers.url;
    // delete signedRequest.headers['content-type'];
    options = {
      method: action.method,
      headers: signedRequest.headers,
    };

    console.log("options that I created: ", options);
  }

  return options;
}

const allMethod = {
  GET(url, headers) {
    return axios
      .get(url, headers)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  },
  POST(url, headers, data) {
    return axios
      .post(url, data, headers)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  },
  PUT(url, headers, data) {
    return axios
      .put(url, data, { headers })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  },
  DELETE(url, headers, data) {
    return axios({
      method: "delete",
      url: url,
      headers,
      data: data,
    });
  },
};

async function call(action, data) {
  let url = process.env.REACT_APP_apiPath + action.api;
  console.log(process.env.REACT_APP_apiPath + action.api);
  let bodyString = JSON.stringify(data);
  console.log(Object.keys(data).length);

  // if (action.method === 'GET' && Object.keys(data).length !== 0) {
  // 	console.log('trying to prepare the url now');

  // 	url = new URL(url);
  // 	url.searchParams.append('meeting_id', data.meeting_id); // adding parameter to url

  // 	console.log('this is the url I have now: ', url);

  // 	url = JSON.parse(JSON.stringify(url)); // changing url back to string

  // 	bodyString = {}; // body should still be empty
  // 	data = {}; // also there should not be any data in here

  // 	action.api = url.replace('https://api.meetus.app', '');

  // 	signedRequest = await SignHeaders(action, data);

  // 	return await allMethod[action.method](url, signedRequest);
  // }

  let signedRequest = {};
  console.log("action here: ", action.api + " And Body: " + bodyString);

  if (!action.noSigning) {
    signedRequest = await SignHeaders(action, data);
    // let signedRequest2 = await createSignature(action, data);
    // console.log("signature here: ", signedRequest, signedRequest2);
  } else {
    signedRequest = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_GATEWAY_KEY,
      },
    };

    if (bodyString) {
      signedRequest.headers.data = bodyString;
    }
    return await allMethod[action.method](url, signedRequest);
  }

  return await allMethod[action.method](url, signedRequest, bodyString);
}

export default call;

import Avatar from "react-avatar";
import { store } from "../../store/store";
import "./profile.scss";
import images from "../../assets/images";

export default function NavbarProfile(props) {
  const state = store.getState();
  const currentUser = state.currentUser;

  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  return (
    <div className="profile__container">
      <Avatar
        size="40"
        // facebook-id="invalidfacebookusername"s
        // src="http://www.gravatar.com/avatar/a16a38cdfe8b2cbd38e8a56ab93238d3"
        src={
          currentUser.profilePic.includes("http")
            ? currentUser.profilePic
            : images.placeholder_avatars[parseInt(currentUser.profilePic)]
        }
        round={true}
      />
      <h4> {currentUser.nickName}</h4>
    </div>
  );
}

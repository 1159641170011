import { Mobile, Tablet, Desktop } from "../styles/Responsive";

import ImgSlider from "../components/imgSlider/ImgSlider";

import "./login.scss";
import ConfirmSignUpForm from "../components/forms/ConfirmSignUpForm";

export default function ConfirmSignUp(props) {
  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <ConfirmSignUpForm getToken={props.getToken} />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <ConfirmSignUpForm getToken={props.getToken} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <ConfirmSignUpForm getToken={props.getToken} />
          <ImgSlider />
        </div>
      </Desktop>
    </>
  );
}

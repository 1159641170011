import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	target: '',
	previous: '',
	screen: ''
};

export const routeSlice = createSlice({
	name: 'route',
	initialState,
	reducers: {},
	extraReducers: {
		setTarget: (state, action) => {
			state.target = action.payload;
			console.log('Stored the target path. ', state.target);
		},
		setPrevious: (state, action) => {
			state.previous = action.payload;
			console.log('Stored the previous path. ', state.target);
		},
		setCurrentScreenMeetingId: (state, action) => {
			state.screen = action.payload;
			console.log('Stored the target path. ', state.target);
		},
	},
});
export const route = state => state.route;
export const {setTarget} = routeSlice.actions;
export default routeSlice.reducer;

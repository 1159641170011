import React, { useRef, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./autocomplete.css";

const libraries = ["places"];
const MapAutocomplete = (props) => {
  const google_key = process.env.REACT_APP_GOOGLE_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: google_key,
    libraries,
  });
  const mapRef = useRef();
  const autocompleteRef = useRef();

  const [placeImageAttributorTitle, setPlaceImageAttributorTitle] =
    useState("");
  const [placeImageAttributorLink, setPlaceImageAttributorLink] = useState("");

  const containerStyle = {
    width: "450px",
    height: "400px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    borderRadius: "5px",
  };

  const inputStyle = {
    borderRadius: "5px",
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "240px",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipses",
    position: "absolute",
    right: "8%",
    top: "11px",
    marginLeft: "-120px",
  };

  const center = {
    lat: 52.50566464,
    lng: 13.372165,
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  async function createAttributorDetails(details) {
    let anchorTag;
    let link;
    let title;
    if (details.photos[0].html_attributions.length > 0) {
      // get HTML-tag from array
      anchorTag = details.photos[0].html_attributions[0];
      // get attributor link from tag
      link = anchorTag.match(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
      );
      // get attributor title from tag
      title = anchorTag.replace(/<[^>]+>/g, "");
      console.log("anchorTag: ", anchorTag);
      console.log("link: ", link);
      console.log("title: ", title);
      setPlaceImageAttributorLink(link[0]);
      setPlaceImageAttributorTitle(title);
    }
  }

  async function handlePlaceChanged() {
    const place = await autocompleteRef.current.getPlace();
    // console.log("Change noted!", place);
    const photoURL = place.photos[0].getUrl({ maxWidth: 500, maxHeight: 500 });

    await createAttributorDetails(place);
    let placeDetails = {
      place_ID: place.place_id,
      place_type: "cardPlace",
      place_title: place.name,
      place_latitude: place.geometry.location.lat,
      place_longitude: place.geometry.location.lng,
      location_address: place.formatted_address,
      place_opening_hours: place.opening_hours?.weekday_text,
      place_link: place.website,
      place_image_link: photoURL,
      place_image_attribution: place?.photos[0]?.html_attributions,
      place_image_attributor_title: placeImageAttributorTitle,
      place_image_attributor_link: placeImageAttributorLink,
      place_rating: place.rating,
      place_tel: place.tel,
      place_website: place.website,
      place_email: place.email,
      place_description: place.description,
    };

    // props.meetingPlace[place.place_id] = placeDetails;
    let newPlace = { [place.place_id]: placeDetails };
    // console.log("this is the new place: ", newPlace);
    props.setMeetingPlace({ ...props.meetingPlace, ...newPlace });
  }

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          onLoad={onLoadMap}
          zoom={10}
        >
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={handlePlaceChanged}
          >
            <input
              type="text"
              placeholder="Search Location"
              style={inputStyle}
            />
          </Autocomplete>
        </GoogleMap>
      )}
    </div>
  );
};

export default MapAutocomplete;

import React, { useEffect, forwardRef } from "react";

import Day from "./Day";

const Calendar = forwardRef((props, ref) => {
  useEffect(() => {}, [props.currentArrDates]);
  return (
    <div className="calendar">
      {props.currentArrDaysInMonth.map((day, index) => {
        let weekday = props.currentArrDayNames[index];

        return (
          <div key={day} ref={props.addToRefs}>
            <Day
              className={
                props.activeDay === day
                  ? "day--active day__container"
                  : "day__container"
              }
              setShowMeeting={props.setShowMeeting}
              setDetailedMeeting={props.setDetailedMeeting}
              setActiveDayOnClick={props.setActiveDayOnClick}
              activeMonth={props.activeMonth}
              dayOfMonth={day}
              dayOfWeek={props.currentArrDayNames[index]}
              date={props.currentArrDates[index]}
            />
            {weekday === "Sun" ? <div className="day__separator"></div> : null}
          </div>
        );
      })}
    </div>
  );
});
export default Calendar;

import { useEffect, useState } from "react";
import "./overlay.scss";
import { useDispatch, useSelector } from "react-redux";
import Network from "../../store/services";
import {
  CreateNewMeeting,
  JoinMyMeeting,
} from "../../store/actions/actionConstants";
import { Scrollbars } from "react-custom-scrollbars";

import { motion, AnimatePresence } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";

import ProgressMeter from "../progressMeter/ProgressMeter";
import Input from "../forms/Input";
import InputMultiLine from "../forms/InputMultiLine";
import OccasionsContainer from "../occasions/OccasionsContainer";
import PickerContainer from "../picker/PickerContainer";
import Button from "../buttons/Button";
import ButtonAddDate from "../buttons/ButtonAddDate";
import TimePicker from "../picker/TimePicker";
import Checkbox from "../forms/Checkbox";

import Map from "../map/Map";
import MapAutocomplete from "../map/MapAutocomplete";
import Autocomplete from "../map/Autocomplete";
import CalendarPicker from "../forms/CalendarPicker";
import CardPlace from "../cards/CardPlace";
import MeetingDetails from "./MeetingDetails";
// import TimePicker from "../forms/TimePicker";
import { store } from "../../store/store";

import {
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
  ViberShareButton,
  ViberIcon,
  TelegramIcon,
  TelegramShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
} from "react-share";

import moment from "moment";
import createShortLink from "../../logic/api/firebaseCreateLink";
// import { FirebaseDynamicLinks } from "firebase-dynamic-links";

export default function Overlay(props) {
  console.log("app Progress: ", props.progress);
  const state = store.getState();
  const currentUser = state.currentUser;
  const dispatch = useDispatch();

  const [meetingTitle, setMeetingTitle] = useState();
  const [searchPlace, setSearchPlace] = useState();
  const [meetingDescription, setMeetingDescription] = useState();

  const [isMidpoint, setIsMidpoint] = useState(false);
  const [isOpenDated, setIsOpenDated] = useState(false);

  const [meetingTime, setMeetingTime] = useState("");
  const [meetingEnd, setMeetingEnd] = useState("");
  const [meetingPlace, setMeetingPlace] = useState({});

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("10:00");
  const [meetingLink, setMeetingLink] = useState();
  const [webMeetingLink, setWebMeetingLink] = useState();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(new Date());
  // console.log("StartTime: ", meetingTime, " EndTime: ", meetingEnd);
  // console.log("this is the meeting Link: ", meetingLink);
  // console.log("meetingPlace: ", meetingPlace);
  // console.log("is Midpoint: ", isMidpoint);
  // console.log("is isOpenDated: ", isOpenDated);

  function resetValues() {
    setMeetingTitle();
    setSearchPlace();
    setMeetingDescription();
    setIsMidpoint(false);
    setIsOpenDated(false);
    setMeetingTime();
    setMeetingEnd();
    setDate();
    setStartTime();
    setEndTime();
    setMeetingLink();
    setWebMeetingLink();
    setSelected();
    props.setProgress(0);
    console.log("reseting values");
  }
  useEffect(() => {
    setMeetingPlace(meetingPlace);
  }, [meetingPlace]);

  useEffect(() => {
    resetValues();
  }, [props.createMeetingModal]);

  const sharedMessage =
    "Hi, I would like you to join this link and then join my meeting via meetUs.\n\n This is a Link for the browser:\n" +
    webMeetingLink +
    "\n" +
    " \n\n And here is the mobile link: \n" +
    meetingLink;

  async function createDynamicLink() {
    const link = await createShortLink(newMeetingID);
    setMeetingLink(link);

    setWebMeetingLink("https://meetus.app/invitation/" + newMeetingID);
  }

  let meeting = {
    meeting_title: meetingTitle,
    meeting_description: meetingDescription,
    agenda: "na",
    meeting_place: isMidpoint ? {} : meetingPlace,
    meeting_type: isMidpoint ? "mp" : "na",
    creation_time: Date.now(),

    meeting_time: isOpenDated ? "" : meetingTime,
    meeting_end_date: isOpenDated ? "" : meetingEnd,
    name: currentUser.nickName,
    user_arn: currentUser.userARN,
    user_id: currentUser.userID,
  };

  useEffect(() => {
    let newDate = moment(selected).format("YYYY-MM-DD") + " " + startTime;
    setMeetingTime(newDate);
  }, [selected, startTime]);

  useEffect(() => {
    let newDate;
    if (startTime < endTime) {
      newDate = moment(selected).format("YYYY-MM-DD") + " " + endTime;
    } else {
      newDate =
        moment(selected).add(1, "d").format("YYYY-MM-DD") + " " + endTime;
    }
    setMeetingEnd(newDate);
  }, [endTime]);

  let newMeetingID = "",
    newMeetingTitle = "",
    newMeetingTopicArn = "";

  async function onCreate() {
    // show loading screen
    setLoading(true);
    try {
      dispatch(
        Network.networkCall({
          action: CreateNewMeeting,
          data: {
            meeting,
          },
        })
      )
        .then(async (resp) => {
          console.log("This is the response from creating a meeting: ", resp);
          if (resp.success === true) {
            newMeetingID = resp.data.meeting_id;
            newMeetingTitle = resp.data.meeting_title;
            newMeetingTopicArn = resp.data.topic_arn;
            // store.dispatch({ type: "ClearPlaces", payload: [] });
            /**
             * Date Time Selected ,
             * No Place Selected
             */
            if (
              resp.data.meeting_time === "Invalid date"
              // resp.data.meeting_time !== "Invalid date"
              // &&
              // meetingPlacesEntries.length === 0
            ) {
              // need to decide what to do at this point
              console.log("I am ending up in the wrong place");
            } else {
              return dispatch(
                Network.networkCall({
                  action: JoinMyMeeting,
                  data: {
                    // Meeting data which is now not only needed from organizer
                    meeting_title: meeting.meeting_title,
                    meeting_time: isOpenDated ? "" : meeting.meeting_time,
                    meeting_description: meeting.meeting_description,
                    agenda: meeting.agenda,
                    meeting_place: isMidpoint ? {} : meeting.meeting_place,
                    meeting_type: meeting.meeting_type,
                    creation_time: meeting.creation_time,
                    meeting_end_date: isOpenDated
                      ? ""
                      : meeting.meeting_end_date,

                    // Data to join a meeting
                    user_id: currentUser.userID,
                    meeting_id: resp.data.meeting_id,
                    name: currentUser.nickName,
                    user_profile_picture: currentUser.profilePic,
                    gps: {
                      lat: "",
                      lng: "",
                    },
                    transport: "",
                    is_recent: "",
                    meetingTime: resp.data.meeting_time,
                    name: currentUser.nickName,
                    user_arn: currentUser.userARN,
                    joining_time: Date.now(),
                    topic_arn: resp.data.topic_arn,
                    creation_time: resp.data.creation_time,
                    organizer_user_id: currentUser.userID,
                    organizer_name: currentUser.nickName,
                  },
                })
              )
                .then((response) => {
                  createDynamicLink();
                  props.nextStep();
                  setLoading(false);
                  /**
                   * No Date Time Selected ,
                   * No Place Selected
                   *
                   * OR
                   *
                   * No Date Time Selected ,
                   * Place Selected
                   */
                  // if (
                  //   (response.data.meeting_time === "" &&
                  //     meetingPlacesEntries.length === 0) ||
                  //   (response.data.meeting_time === "" &&
                  //     meetingPlacesEntries.length >= 0)
                  // ) {
                  //   //NEEED TO FIGURE THIS ONE OUT
                  // } else {
                  //   /**
                  //    * Date Time Selected ,
                  //    * Places Selected
                  //    */
                  //   console.log(
                  //     "-------------------------------- meetingID: ",
                  //     newMeetingID
                  //   );
                  //   let noOfPlaces = meetingPlacesEntries.length;
                  //   if (noOfPlaces <= 1) {
                  //     // need to figure this pout
                  //   } else {
                  //     // need to figure this pout
                  //   }
                  // }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log("Problem joining the meeting", err);
                });
            }
          } else {
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const contentAnim = {
    initial: {
      opacity: 0,
      left: "20%",
    },
    isOpen: {
      opacity: 1,
      left: "0%",
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    exit: { opacity: 0, left: "-20%" },
  };
  return (
    <>
      <h3>Create Meeting</h3>
      <ProgressMeter
        width="70%"
        steps={props.steps}
        progress={props.progress}
      />
      <div className="modalContentContainer">
        {/* Step 1 */}
        <AnimatePresence mode="wait">
          {props.progress === 0 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              <Input
                type="text"
                inputId="meetingTitle"
                required="required"
                labelId="meetingTitleLabel"
                labelString="MEETING TITLE"
                detectInput={(e) => setMeetingTitle(e.target.value)}
              />
              <InputMultiLine
                type="text"
                inputId="meetingDescription"
                required="required"
                labelId="meetingDescriptionLabel"
                labelString="DESCRIPTION"
                detectInput={(e) => setMeetingDescription(e.target.value)}
              />

              {/* <OccasionsContainer /> */}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {/* Step 2 */}
          {props.progress === 1 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              <div className="calendarSlotPicker">
                <div>
                  <div className="checkboxContainer">
                    <Checkbox
                      labelText="We need to find a date first."
                      onChange={setIsOpenDated}
                      check={isOpenDated}
                    />
                  </div>

                  <div
                    style={{ margin: "1%", opacity: isOpenDated ? 0.25 : 1 }}
                  >
                    <div>
                      <CalendarPicker
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </div>
                    <div className="timelineContainer">
                      <h3>Start</h3>
                      <TimePicker setTime={setStartTime} time={startTime} />
                      {/* <PickerContainer /> */}

                      <h3>End</h3>
                      {meetingEnd === "" ? (
                        <div onClick={() => setMeetingEnd("12")}>
                          <span>open end</span>
                        </div>
                      ) : (
                        <TimePicker setTime={setEndTime} time={endTime} />
                      )}
                    </div>
                  </div>
                </div>
                {/* <ButtonAddDate  onClick={}/> */}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {/* Step 2 */}
          {props.progress === 2 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              <div className="checkboxContainer">
                <Checkbox
                  labelText="We will search the midpoint once all have joined."
                  onChange={setIsMidpoint}
                  check={isMidpoint}
                />
              </div>

              <div
                className="findPlaces"
                style={{
                  opacity: isMidpoint ? 0.25 : 1,
                }}
              >
                <div className="addedPlaces">
                  {Object.values(meetingPlace).length > 0 ? (
                    <div>
                      {Object.values(meetingPlace).map((place) => {
                        return <CardPlace place={place} key={place} />;
                      })}
                    </div>
                  ) : (
                    <div className="noPlaces">
                      <span>I have NO places</span>
                    </div>
                  )}
                </div>

                <div className="mapContainer">
                  <MapAutocomplete
                    meetingPlace={meetingPlace}
                    setMeetingPlace={setMeetingPlace}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {/* Step 2 */}
          {props.progress === 3 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              <div>
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#ED3424"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={loading}
                />
                {loading ? null : <MeetingDetails meeting={meeting} />}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {/* Step 2 */}
          {props.progress === 4 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              {meetingLink ? (
                <>
                  <span>
                    {"iOS & Android Link: \n" +
                      meetingLink +
                      "\n\n" +
                      "Browser Link to Join meeting:\n" +
                      webMeetingLink}
                  </span>
                </>
              ) : (
                <span>
                  {"We could not create the link to share the meeting."}
                </span>
              )}
              {meetingLink ? (
                <div>
                  <EmailShareButton
                    url={sharedMessage}
                    subject={"Meeting Invite for: " + meetingTitle}
                    body=""
                    className="Demo__some-network__share-button"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <LinkedinShareButton
                    url={sharedMessage}
                    className="Demo__some-network__share-button"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <ViberShareButton
                    url={sharedMessage}
                    title={"Meeting Invite for: " + meetingTitle}
                    className="Demo__some-network__share-button"
                  >
                    <ViberIcon size={32} round />
                  </ViberShareButton>
                  <TelegramShareButton
                    url={sharedMessage}
                    title={"Let's meet"}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>

                  <FacebookShareButton
                    url={sharedMessage}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <FacebookShareCount
                    url={sharedMessage}
                    className="Demo__some-network__share-count"
                  >
                    {(count) => count}
                  </FacebookShareCount>

                  <FacebookMessengerShareButton
                    url={sharedMessage}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>
                </div>
              ) : null}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div style={{ margin: 90 }} />
      <div className="buttonGroup">
        <Button
          name={props.progress === 0 ? "Cancel" : "Back"}
          buttonClick={
            props.progress === 0 ? props.toggleMeetingModal : props.prevStep
          }
          className="plain buttonPrev"
        />
        <Button
          name={
            props.progress === 3
              ? "Create"
              : props.progress === 4
              ? "Done"
              : "Confirm"
          }
          buttonClick={async () => {
            switch (props.progress) {
              case 0:
                if (meetingDescription != "" && meetingTitle != "") {
                  props.nextStep();
                }
                return;
              case 1:
                if (meetingTime != "") {
                  props.nextStep();
                }
                return;
              case 2:
                if (Object.values(meetingPlace).length != 0 || isMidpoint) {
                  props.nextStep();
                }
                return;
              case 3:
                await onCreate();

                return;
              case 4:
                props.toggleMeetingModal(false);
                return;
            }
            // if (props.progress === 3) {

            // } else {
            //   props.nextStep();
            // }
          }}
          className="plain"
        />
      </div>
    </>
  );
}

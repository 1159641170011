import { NavLink } from 'react-router-dom';

export default function NavItem(props) {
  return (
    <NavLink
      exact={true}
      to={props.to}
      className="tabnav__item"
      activeClassName="tabnav__item--active"
    >
      {props.icon}
      <span>{props.name}</span>
    </NavLink>
  );
}

import * as AWS from "aws-sdk/global";

import Storage from "../localStorage";

var identityPool = {
  IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
  region: process.env.REACT_APP_region,
};
let userPoolLink =
  "cognito-idp.eu-central-1.amazonaws.com/" + process.env.REACT_APP_UserPoolId;

async function getCredentials(jwtToken) {
  //POTENTIAL: Region needs to be set if not already set previously elsewhere.
  AWS.config.region = identityPool.region;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPool.IdentityPoolId, // your identity pool id here
    Logins: {
      // Change the key below according to the specific region your user pool is in.
      [userPoolLink]: jwtToken,
    },
  });

  //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
  return new Promise((resolve, reject) =>
    AWS.config.credentials.refresh(function (err, result) {
      if (err) {
        reject(err);
      } else {
        // Instantiate aws sdk service objects now that the credentials have been updated.
        // example: var s3 = new AWS.S3();
        console.log("Successfully logged!");
        // console.log(AWS.config.credentials.data);

        console.log(AWS.config.credentials.data);
        Storage.set(
          "@SessionToken",
          AWS.config.credentials.data.Credentials.SessionToken
        );
        Storage.set(
          "@SecretKey",
          AWS.config.credentials.data.Credentials.SecretKey
        );
        Storage.set(
          "@AccessKeyId",
          AWS.config.credentials.data.Credentials.AccessKeyId
        );
        resolve(AWS.config.credentials.data.Credentials);
      }
    })
  );
}

export default getCredentials;

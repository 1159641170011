import { useState, useEffect, useRef } from "react";
import { store } from "../../store/store";
import { UpdateMeetingDetails } from "../../store/actions/actionConstants";
import Network from "../../store/services";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
// import "./details.scss";
import tmpicon from "../../assets/img/icon.JPG";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
} from "../../assets/images";
import Input from "../forms/Input";

import ChangeTime from "../changeTime/ChangeTime";
import ChangePlace from "../map/ChangePlace";

export default function ChangeMeetingDetails(props) {
  const dispatch = useDispatch();
  const state = store.getState();
  const currentUser = state.currentUser;
  const [changeDescription, setChangeDescription] = useState(false);

  console.log("What I have in the changeMeetingDetails: ", props);
  function handleChangeDescription(e) {
    setChangeDescription(e.target.value);
  }
  const [changed, setChanged] = useState(false);

  const [meetingID, setMeetingID] = useState(
    props.meeting?.MeetingID ? props.meeting?.MeetingID : ""
  );
  const [meetingTitle, setMeetingTitle] = useState(
    props.meeting?.MeetingTitle ? props.meeting?.MeetingTitle : ""
  );
  const [searchPlace, setSearchPlace] = useState();
  const [meetingDescription, setMeetingDescription] = useState(
    props.meeting?.MeetingDescription ? props.meeting?.MeetingDescription : ""
  );

  const [isMidpoint, setIsMidpoint] = useState(false);
  const [isOpenDated, setIsOpenDated] = useState(false);

  const [meetingTime, setMeetingTime] = useState(
    props.meeting?.MeetingTime ? props.meeting?.MeetingTime : ""
  );
  const [meetingEnd, setMeetingEnd] = useState(
    props.meeting?.MeetingEnd ? props.meeting?.MeetingEnd : ""
  );
  const [meetingPlace, setMeetingPlace] = useState(
    props.meeting?.MeetingPlace ? props.meeting?.MeetingPlace : {}
  );

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(
    props.meeting?.MeetingTime
      ? moment(props.meeting?.MeetingTime).format("HH:mm")
      : "10:00"
  );
  const [endTime, setEndTime] = useState(
    props.meeting?.MeetingEnd
      ? moment(props.meeting?.MeetingEnd).format("HH:mm")
      : "10:00"
  );
  const [meetingLink, setMeetingLink] = useState();
  const [webMeetingLink, setWebMeetingLink] = useState();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(new Date(props.meetingTime));

  const places =
    props.meeting != "" && props.meeting != undefined
      ? Object.values(props.meetingPlace)
      : [];

  let meeting = {
    meeting_id: meetingID,
    meeting_title: meetingTitle,
    description: meetingDescription,
    meeting_place: places,
    start_date: meetingTime,
    end_date: meetingEnd,
    user_id: currentUser.userID,
  };

  // let meeting = {
  //   meeting_title: meetingTitle,
  //   meeting_description: meetingDescription,
  //   agenda: "na",
  //   meeting_place: isMidpoint ? {} : meetingPlace,
  //   meeting_type: isMidpoint ? "mp" : "na",
  //   creation_time: Date.now(),

  //   meeting_time: isOpenDated ? "" : meetingTime,
  //   meeting_end_date: isOpenDated ? "" : meetingEnd,
  //   name: currentUser.nickName,
  //   user_arn: currentUser.userARN,
  //   user_id: currentUser.userID,
  // };

  function resetValues() {
    setMeetingTitle();
    setSearchPlace();
    setMeetingDescription();
    setIsMidpoint(false);
    setIsOpenDated(false);
    setMeetingTime();
    setMeetingEnd();
    setDate();
    setStartTime();
    setEndTime();
    setMeetingLink();
    setWebMeetingLink();
    setSelected();
    props.setProgress(0);
    console.log("reseting values");
  }
  useEffect(() => {
    if (props.changing === true) {
      props.setLoading(true);
      UpdateMeeting();
      props.setChanging(false);
    }
  }, [props.changing]);

  async function UpdateMeeting() {
    dispatch(
      Network.networkCall({
        action: UpdateMeetingDetails,
        data: {
          meeting,
        },
      })
    )
      .then(async (resp) => {
        console.log(
          "This is the response from update meeting details : ",
          resp
        );
        if (resp.success === true) {
          store.dispatch({ type: "ClearPlaces", payload: [] });
          setChanged(true);
          resetValues();
          setLoading(false);
        } else {
          props.setLoading(false);
          props.nextStep();
          // crashlytics().log("Failed_to_update_meeting.");
        }
      })
      .catch((err) => {
        // setLoading(false);
        // crashlytics().recordError(err);
        props.setLoading(false);
        console.log("Error creating a meeting: ", err);
      });
  }

  return (
    <>
      {!props.changeTime && !props.changePlace ? (
        <div className="d-details__container">
          <span className="meeting__details">{"Meeting Details"}</span>
          <div>
            <span className="meeting__name">{meetingTitle}</span>
            <img className="change_icon" src={change} alt="icon" />
          </div>
          {/* <div>
            

            <span className="participant__text">{props.meeting?.UserName}</span>
          </div> */}

          {!changeDescription ? (
            <div onClick={() => setChangeDescription(true)}>
              <span className="restaurant__text">{meetingDescription}</span>
              <img className="change_icon" src={change} alt="icon" />
            </div>
          ) : (
            <Input
              type="text"
              value={meetingDescription}
              inputId="meetingDescription"
              required="required"
              labelId="meetingDescriptionLabel"
              labelString="MEETING DESCRIPTION"
              detectInput={(e) => setMeetingDescription(e.target.value)}
            >
              {}
            </Input>
          )}
          <div className="restaurant__info">
            <div
              className="restaurant__data"
              onClick={() => props.setChangePlace(true)}
            >
              <img className="change_icon" src={place} alt="icon" />
              <span className="restaurant__text">
                {places?.length >= 1 && places[0]?.place_type != "cardMap"
                  ? places[0].place_title
                  : places?.length === 1 && places[0]?.place_type === "cardMap"
                  ? "Place Address"
                  : "na"}
              </span>
              <img className="change_icon" src={change} alt="icon" />
            </div>

            <div onClick={() => props.setChangeTime(true)}>
              <div className="restaurant__data">
                <img className="change_icon" src={calendar} alt="icon" />
                <span className="restaurant__text">
                  {moment(new Date(meetingTime)).format("DD.MM.YYYY")}
                </span>
                <img className="change_icon" src={change} alt="icon" />
              </div>
              <div className="restaurant__data1">
                <img className="change_icon" src={time} alt="icon" />
                <span className="restaurant__text">
                  {moment(new Date(meetingTime)).format("HH:mm")}
                </span>
                {/* <img className="change_icon" src={change} alt="icon" /> */}
              </div>
            </div>
          </div>
        </div>
      ) : props.changePlace ? (
        <>
          <ChangePlace
            setIsMidpoint={setIsMidpoint}
            isMidpoint={isMidpoint}
            meetingPlace={meetingPlace}
            setMeetingPlace={setMeetingPlace}
            setChangePlace={props.setChangePlace}
          />
        </>
      ) : changed ? (
        <>
          <span> {"The meeting was changed"}</span>
        </>
      ) : (
        <>
          <ChangeTime
            setChangeTime={props.setChangeTime}
            setStartTime={setStartTime}
            startTime={startTime}
            endTime={endTime}
            setEndTime={setEndTime}
            meetingTime={meetingTime}
            setMeetingTime={setMeetingTime}
            setMeetingEnd={setMeetingEnd}
            meetingEnd={meetingEnd}
            selected={props.selected}
            setSelected={props.setSelected}
          />
        </>
      )}
    </>
  );
}

import { useEffect, useState } from "react";

import {
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
  ViberShareButton,
  ViberIcon,
  TelegramIcon,
  TelegramShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
} from "react-share";

import createShortLink from "../../logic/api/firebaseCreateLink";

export default function ShareMeeting(props) {
  const [meetingLink, setMeetingLink] = useState();
  const [webMeetingLink, setWebMeetingLink] = useState();

  async function createDynamicLink() {
    const link = await createShortLink(props.meetingID);
    setMeetingLink(link);

    setWebMeetingLink("https://meetus.app/invitation/" + props.meetingID);
  }
  useEffect(() => {
    createDynamicLink();
  }, []);

  const sharedMessage =
    "Hi, I would like you to join this link and then join my meeting via meetUs.\n\n This is a Link for the browser:\n" +
    webMeetingLink +
    "\n" +
    " \n\n And here is the mobile link: \n" +
    meetingLink;
  return (
    <>
      {/* <span>
        {"iOS & Android Link: \n" +
          meetingLink +
          "\n\n" +
          "Browser Link to Join meeting:\n" +
          webMeetingLink}
      </span> */}
      <span>{"Share Meeting:"}</span>
      <div>
        <EmailShareButton
          url={sharedMessage}
          subject={"Meeting Invite for: " + props.meetingTitle}
          body=""
          className="Demo__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
        <LinkedinShareButton
          url={sharedMessage}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <ViberShareButton
          url={sharedMessage}
          title={"Meeting Invite for: " + props.meetingTitle}
          className="Demo__some-network__share-button"
        >
          <ViberIcon size={32} round />
        </ViberShareButton>
        <TelegramShareButton
          url={sharedMessage}
          title={"Let's meet"}
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <FacebookShareButton
          url={sharedMessage}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <FacebookShareCount
          url={sharedMessage}
          className="Demo__some-network__share-count"
        >
          {(count) => count}
        </FacebookShareCount>

        <FacebookMessengerShareButton
          url={sharedMessage}
          appId="521270401588372"
          className="Demo__some-network__share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>{" "}
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/localStorage";
// import config from 'react-native-config';
// import { Platform } from 'react-native';

const initialState = {
  links: [],
  code: "",
  joinMeetingId: "",
};

export const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {},
  extraReducers: {
    // 	loadDeepLinks: (state, action) => {
    // 		console.log('....loadDeepLinks....', action.payload);
    // 		return {
    // 			...state,
    // 			links: action.payload
    // 		};
    // 	},
    // 	setDeepLinks: (state, action) => {
    // 		try {
    // 			const payload = action.payload.toString();
    // 			console.log('setDeepLinks : ', state.links);
    // 			if (isEmpty(state.links) || state.links.length === 0) {
    // 				console.log('state.links empty');
    // 				if (Platform.OS === 'ios') {
    // 					if (!payload.includes(config.bundleId)) {
    // 						let links = [];
    // 						links.push(action.payload);
    // 						Storage.setJson('@links', links);
    // 						return {
    // 							...state,
    // 							links: links,
    // 						};
    // 					}
    // 				} else {
    // 					if (!payload.includes(config.androidPackage)) {
    // 						let links = [];
    // 						links.push(action.payload);
    // 						Storage.setJson('@links', links);
    // 						return {
    // 							...state,
    // 							links: links,
    // 						};
    // 					}
    // 				}
    // 			} else {
    // 				const existItem = state?.links?.find((link) => link === action.payload);
    // 				Storage.setJson('@links', (existItem ? state.links : [...state.links, action.payload]));
    // 				return {
    // 					...state,
    // 					links: existItem ? state.links : [...state.links, action.payload],
    // 				};
    // 			}
    // 		} catch (error) {
    // 			console.log('error : ', error);
    // 		}
    // 	},
    // 	clearAllDeepLinks: (state, action) => {
    // 		console.log('....clearAllDeepLinks...');
    // 		Storage.setJson('@links', []);
    // 		return {
    // 			...state,
    // 			links: [],
    // 		};
    // 	},
    // 	clearDeepLink: (state, action) => {
    // 		console.log('....clearDeepLink...', state.links + '//' + state.links.length);
    // 		if (!isEmpty(state.links) || state.links.length !== undefined) {
    // 			const remainingLinks = state.links.filter(x => !x.includes(action.payload));
    // 			Storage.setJson('@links', remainingLinks);
    // 			return {
    // 				...state,
    // 				links: remainingLinks,
    // 				joinMeetingId: ''
    // 			};
    // 		}
    // 	},
    // 	clearForgotPassAndConfirmationLinks: (state, action) => {
    // 		console.log('....clearForgotPassAndConfirmationLinks...', state.links + '//' + state.links.length);
    // 		if (!isEmpty(state.links) || state.links.length !== undefined) {
    // 			const remainingLinks = state.links.filter(x => !x.includes('signup'));
    // 			Storage.setJson('@links', remainingLinks);
    // 			return {
    // 				...state,
    // 				links: remainingLinks,
    // 				code: ''
    // 			};
    // 		}
    // 	},
    // 	setConfirmationCode: (state, action) => {
    // 		return {
    // 			...state,
    // 			code: action.payload,
    // 		};
    // 	},
    // 	setJoinMeetingId: (state, action) => {
    // 		return {
    // 			...state,
    // 			joinMeetingId: action.payload,
    // 		};
    // 	}
  },
});

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const links = (state) => state.links;
export default linksSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/localStorage";
// import {store} from '../store';

const initialState = {
  isLogin: false,
  firstOpen: false,
  firstMeetingCreated: true,
  firstMeetingJoined: true,
  firstMidpointMeeting: true,
  firstStartPointSet: "",
  guestStatus: "",
  adPaid: false,
  // access keys and tokens - perfect candidates for the store?
  sessionToken: "",
  secretKey: "",
  accessKeyId: "",
  // user data and candidates for the local storage
  profilePic: "",
  email: "",
  lastName: "",
  firstName: "",
  userName: "",
  gender: "",
  nickName: "",

  // user backend details that are required later in the process should stay in local storage
  userID: "",
  userARN: "",
  fcmToken: "",
  tempPw: "",
  timestamp: "",
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {},
  extraReducers: {
    SetFirstOpen: (state, action) => {
      let firstOpen = action.payload.firstOpen;
      if (firstOpen === "true") {
        state.firstOpen = true;
      } else if (firstOpen === "false") {
        state.firstOpen = false;
      }
      Storage.set("@firstOpen", "false");
    },
    SetFirstMeetingCreated: (state, action) => {
      let firstMeetingCreated = action.payload.firstMeetingCreated;
      if (firstMeetingCreated === "true") {
        state.firstMeetingCreated = true;
        Storage.set("@firstMeetingCreated", "true");
      } else if (firstMeetingCreated === "false") {
        state.firstMeetingCreated = false;
      }
    },
    SetFirstMeetingJoined: (state, action) => {
      let firstMeetingJoined = action.payload.firstMeetingJoined;
      if (firstMeetingJoined === "true") {
        state.firstMeetingJoined = true;
        Storage.set("@firstJoin", "true");
      } else if (firstMeetingJoined === "false") {
        state.firstMeetingJoined = false;
      }
    },
    SetFirstMidpointMeeting: (state, action) => {
      let firstMidpointMeeting = action.payload.firstMidpointMeeting;
      if (firstMidpointMeeting === "true") {
        state.firstMidpointMeeting = true;
        state.firstStartPointSet = "";
        Storage.set("@firstMidpointMeeting", "true");
      } else if (firstMidpointMeeting === "false") {
        state.firstMidpointMeeting = false;
      }
    },
    SetFirstStartPointSet: (state, action) => {
      let firstStartPointSet = action.payload.firstStartPointSet;
      if (firstStartPointSet === "true") {
        state.firstStartPointSet = true;
        Storage.set("@firstStartPointSet", "true");
      } else if (firstStartPointSet === "false") {
        state.firstStartPointSet = false;
      }
    },
    CleanSubscriptionsSuccess: (state, action) => {
      Storage.clearData();
      console.log(
        "Loged User out and deleted all data from store. [Redux-Store]"
      );
      return {
        isLogin: false,
        firstOpen: false,
        guestStatus: "",
        adPaid: false,
        // access keys and tokens - perfect candidates for the store?
        sessionToken: "",
        secretKey: "",
        accessKeyId: "",
        // user data and candidates for the local storage
        profilePic: "",
        email: "",
        lastName: "",
        firstName: "",
        userName: "",
        gender: "",
        nickName: "",

        // user backend details that are required later in the process should stay in local storage
        userID: "",
        userARN: "",
        fcmToken: "",
      };
    },
    ResubscribeSuccess: (state, action) => {
      console.log("Answer from resubscribe: ", action.payload);
      let NewARN = action.payload.endpoint_arn;
      console.log(console.log(typeof action.payload));
      state.userARN = NewARN;

      Storage.set("@UserArn", NewARN);
      console.log(
        "Set the new token as the only token and set the new ARN. [Redux-Store]"
      );
    },
    GuestLogOut: (state, action) => {
      Storage.clearData();

      console.log(
        "Loged User out and deleted all data from store. [Redux-Store]"
      );
      return {
        isLogin: false,
        guestStatus: "",
        adPaid: false,
        // access keys and tokens - perfect candidates for the store?
        sessionToken: "",
        secretKey: "",
        accessKeyId: "",
        // user data and candidates for the local storage
        profilePic: "",
        email: "",
        lastName: "",
        firstName: "",
        userName: "",
        gender: "",
        nickName: "",

        // user backend details that are required later in the process should stay in local storage
        userID: "",
        userARN: "",
        fcmToken: "",
      };
    },
    SetFcmToken: (state, action) => {
      let FcmToken = action.payload.fcmToken;

      state.fcmToken = FcmToken;

      Storage.set("@firebaseToken", FcmToken);
      console.log("Stored firebase token. [Redux-Store]");
    },
    // Add reducers for additional action types here, and handle loading state as needed
    GlobalAuthSuccess: (state, action) => {
      let Credentials = action.payload.Credentials;
      let SessionToken = Credentials.SessionToken;
      let SecretKey = Credentials.SecretKey;
      let AccessKeyId = Credentials.AccessKeyId;
      if (SessionToken && SessionToken.length > 0) {
        state.isLogin = true;
        state.sessionToken = SessionToken;
        state.secretKey = SecretKey;
        state.accessKeyId = AccessKeyId;
        Storage.set("@SessionToken", SessionToken);
        Storage.set("@SecretKey", SecretKey);
        Storage.set("@AccessKeyId", AccessKeyId);
        console.log("Stored a set of new credentials. [Redux-Store]");
      }
    },
    CreateUserSuccess: (state, action) => {
      // this reflects backend user creation for user ID and ARN
      console.log("receiving payload: ", action.payload);
      console.log("it has the type: ", typeof action.payload);
      action.payload = JSON.parse(action.payload);
      console.log("Type after parsing: ", typeof action.payload);
      let ProfilePic = action.payload.user_picture.toString();
      let UserName = action.payload.user_name;
      let FirstName = action.payload.user_firstname;
      let UserFamilyName = action.payload.user_familyname;
      let UserMail = action.payload.user_email;
      let GuestStatus = action.payload.guest_status;
      let UserID = action.payload.user_id;
      let AppARN = action.payload.app_arn;
      let UserARN = action.payload.endpoint;
      let NickName = action.payload.user_nickname;
      let Timestamp = action.payload.timestamp;

      if (UserName && UserName.length > 0) {
        state.isLogin = true; // this will need to be changed
        state.profilePic = ProfilePic;
        state.userName = UserName;
        state.firstName = FirstName;
        state.lastName = UserFamilyName;
        state.nickName = NickName;
        state.email = UserMail;
        state.userID = UserID;
        state.userARN = UserARN;
        state.guestStatus = GuestStatus;
        state.timestamp = "" + Timestamp;
        // Storage.set('@userName', UserName);  should be coming from the sign up
        Storage.set("@GuestStatus", GuestStatus);
        Storage.set("@UserName", UserName);
        Storage.set("@NickName", NickName);
        Storage.set("@UserLastName", UserFamilyName);
        Storage.set("@UserFirstName", FirstName);
        Storage.set("@ProfilePic", ProfilePic);
        Storage.set("@UserMail", UserMail);
        Storage.set("@UserArn", UserARN);
        Storage.set("@AppArn", AppARN);
        Storage.set("@UserID", UserID);
        Storage.set("@timestamp", "" + Timestamp);
        console.log("Your User was stored. [Redux-Store]");
      }
    },
    SignUpUserSuccess: (state, action) => {
      // this is now the signUp in the userPool
      let UserName = action.payload.email;
      let UserPassword = action.payload.password; // should not be part of this
      let UserLastName = action.payload.familyName;
      let UserFirstName = action.payload.firstName;
      let UserEmail = action.payload.email;
      let UserNickName = action.payload.nickName;
      let ConfirmProcess = action.payload.confirmProcess;

      if (UserEmail && UserEmail.length > 0) {
        state.isLogin = false; // propably not needed here...
        state.userName = UserName;
        state.firstName = UserFirstName;
        state.lastName = UserLastName;
        state.email = UserEmail;
        state.nickName = UserNickName;
        state.tempPw = UserPassword;
        // is there actually any action that needs to take place? For now putting stuff in local storage
        Storage.set("@UserName", UserName);
        Storage.set("@UserLastName", UserLastName);
        Storage.set("@UserFirstName", UserFirstName);
        Storage.set("@NickName", UserNickName);
        Storage.set("@UserEmail", UserEmail);
        Storage.set("@tempPw", UserPassword);
        Storage.set("@ConfirmProcess", ConfirmProcess);
      }
    },
    ForgetPasssword: (state, action) => {
      // this is now the signUp in the userPool
      let UserName = action.payload.userName;
      let ConfirmProcess = action.payload.confirmProcess;

      if (UserName.length > 0) {
        state.isLogin = false; // propably not needed here...
        state.userName = UserName;

        Storage.set("@UserName", UserName);
        Storage.set("@ConfirmProcess", ConfirmProcess);
      }
    },

    LoginUserSuccess: (state, action) => {
      let SessionToken = action.payload.SessionToken;
      let SecretKey = action.payload.SecretKey;
      let AccessKeyId = action.payload.AccessKeyId;
      let Expiration = action.payload.Expiration;

      if (SessionToken && SessionToken.length > 0) {
        state.isLogin = true;
        state.sessionToken = SessionToken;
        state.secretKey = SecretKey;
        state.accessKeyId = AccessKeyId;
        Storage.set("@Expiration", Expiration);
        Storage.set("@LoginStatus", "true"); // You can only store strings it seems
        Storage.set("@SessionToken", SessionToken);
        Storage.set("@SecretKey", SecretKey);
        Storage.set("@AccessKeyId", AccessKeyId);
      }
    },
    StartUp: (state, action) => {
      // we need to put here what all needs to be loaded when the app is started
      let GuestStatus = action.payload.response[0][1];
      let UserEmail = action.payload.response[1][1];
      let UserARN = action.payload.response[2][1];
      let EndPoint = action.payload.response[3][1];
      let UserID = action.payload.response[4][1];
      let NickName = action.payload.response[5][1];
      let UserLastName = action.payload.response[6][1];
      let UserFirstName = action.payload.response[7][1];
      let ProfilePic = action.payload.response[8][1];
      let Timestamp = action.payload.response[9][1];
      if (GuestStatus) {
        state.isLogin = true;
        state.guestStatus = GuestStatus;
        state.email = UserEmail;
        state.userARN = UserARN;
        state.endpoint = EndPoint;
        state.userID = UserID;
        state.nickName = NickName;
        state.userName = UserEmail;
        state.lastName = UserLastName;
        state.firstName = UserFirstName;
        state.profilePic = ProfilePic;
        state.timestamp = "" + Timestamp;
        console.log("I stored User data. [Redux-Store]");
      }
    },

    GetUserDataSuccess: (state, action) => {
      console.log("Loaded the User data: ", action.payload.Items);

      for (const item of action.payload.Items) {
        console.log(item);
        let ProfilePic = item.user_picture.toString();
        let UserName = item.user_name;
        let UserFirstName = item.user_firstname;
        let UserFamilyName = item.user_familyname;
        let UserMail = item.user_email;
        let GuestStatus = item.guest_status;
        let UserID = item.user_id;
        let AppARN = item.app_arn;
        let UserARN = item.endpoint;
        let NickName = item.user_nickname;
        //let FcmToken = item.token;
        let Timestamp = item.timestamp;

        state.profilePic = ProfilePic;
        state.userName = UserName;
        state.firstName = UserFirstName;
        state.lastName = UserFamilyName;
        state.nickName = NickName;
        state.email = UserMail;
        state.userID = UserID;
        state.userARN = UserARN;
        state.guestStatus = GuestStatus;
        //state.fcmToken = FcmToken;
        state.timestamp = "" + Timestamp;
        // Storage.set('@userName', UserName);  should be coming from the sign up
        Storage.set("@GuestStatus", GuestStatus);
        Storage.set("@UserName", UserName);
        Storage.set("@NickName", NickName);
        Storage.set("@UserLastName", UserFamilyName);
        Storage.set("@UserFirstName", UserFirstName);
        Storage.set("@ProfilePic", ProfilePic);
        Storage.set("@UserMail", UserMail);
        Storage.set("@UserArn", UserARN);
        Storage.set("@AppArn", AppARN);
        Storage.set("@UserID", UserID);
        //Storage.set('@firebaseToken', FcmToken);
        Storage.set("@timestamp", "" + Timestamp);
        console.log("Your User was stored. [Redux-Store]");
      }

      console.log("Loaded User Data into Store. [Redux-Store]");
    },
    UploadProfilePicSuccess: (state, action) => {
      state.profilePic = action.payload?.imageURL;
      Storage.set("@ProfilePic", action.payload?.imageURL);
    },
  },
});

export const { set, logout } = currentUserSlice.actions;
export const currentUser = (state) => state.currentUser;
export default currentUserSlice.reducer;

import { store } from "../../store/store";
import moment from "moment";
import "./placeVote.scss";
import tmpicon from "../../assets/img/icon.JPG";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
} from "../../assets/images";
import { ThreeDots } from "react-loader-spinner";

import CardPlace from "../cards/CardPlace";
import Button from "../Button";

export default function PlaceVote(props) {
  const places = props.meetingPlaces ? Object.values(props.meetingPlaces) : [];

  return (
    <>
      <div>
        {Object.values(props.meetingPlaces).map((place) => {
          return (
            <div className="placeRow">
              <CardPlace
                place={place}
                key={place}
                meetingPlaces={props.meetingPlaces}
              />
              <input
                className="checkbox"
                type="checkbox"
                onChange={() => {
                  props.addVote(place.place_ID);
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

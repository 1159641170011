import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";
import thunk from "redux-thunk";

// // import reducers
// user management
import getFCMTokenReducer from "./reducers/getFCMTokenReducer";
import currentUser from "./reducers/currentUser";
// meetings
import meetings from "./reducers/meetings";
// UI Theme
import setUserPreferences from "./reducers/userPreferences";
import route from "./reducers/route";
import chats from "./reducers/chats";
import pullstate from "./reducers/pullstateReducer";
import links from "./reducers/deepLinks";
import places from "./reducers/places";
import notifications from "./reducers/notifications";
import slots from "./reducers/slots";

const rootReducer = combineReducers({
  getFCMTokenReducer,
  currentUser,
  meetings,
  setUserPreferences,
  route,
  chats,
  pullstate,
  links,
  places,
  notifications,
  slots,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const purgeData = async () => {
  await persistor.purge();
};

export const persistor = persistStore(store);

// import localStorage from '@react-native-async-storage/async-storage';
// /**
//  * localStorage api.
//  */

const get = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      // value previously stored
      return value;
    }
  } catch (e) {
    // error reading value
  }
};

const set = async (key, value) => {
  try {
    return await localStorage.setItem(key, value);
  } catch (e) {
    // saving error
  }
};

const getJson = async (key) => {
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    console.log("An error occured retrieving data:", e);
  }
};

const getMultiple = async (keys) => {
  try {
    return await localStorage.multiGet(keys); // ['@MyApp_user', '@MyApp_key'] this is what the key need to look like
  } catch (e) {
    // read error
  }
};

const setJson = async (key, data) => {
  try {
    const jsonValue = JSON.stringify(data);
    await localStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

const addItem = async (key, data) => {
  try {
    let array = await localStorage.getItem(key);
    console.log(typeof array, array);

    if (array === null) {
      array = [];
    } else {
      array = JSON.parse(array);
    }
    console.log(typeof array, array);

    array.push(data);
    console.log("New array: ", array);

    const jsonValue = JSON.stringify(array);
    console.log(typeof jsonValue, jsonValue);
    return await localStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log("An error occured while adding the item:", e);
  }
};

const remove = async (key) => {
  try {
    return await localStorage.removeItem(key);
  } catch (e) {
    // remove error
  }

  console.log("Done.");
};

const clearData = async (key) => {
  try {
    localStorage.getAllKeys().then((keys) => localStorage.multiRemove(keys));
  } catch (e) {
    console.log("An error occured while clearing all data: ", e);
  }
};

export default {
  get,
  set,
  getJson,
  setJson,
  addItem,
  getMultiple,
  remove,
  clearData,
};

import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
// import { useTheme } from "@react-navigation/native";
// import { useTranslation } from "react-i18next";

import { participants } from "../../assets/images";

import images from "../../assets/images";

import "./participants.scss";

const Participants = (props) => {
  // const {dark, colors} = useTheme();
  // const {t} = useTranslation();

  // console.log(
  //   "--------------------------- participants log -------------------------------------",
  //   "these are the participants: ",
  //   props.participants
  // );
  // console.log(props.participants);
  const objectArray = Object.entries(props.participants);
  return (
    <div>
      <div>
        {props.participants &&
          objectArray.map(([key, value]) => {
            console.log(value);
            return (
              <div
                className={
                  value.JoinerID === value.OrganizerID
                    ? "organizer"
                    : "participant"
                }
                key={key}
                onClick={() => ""}
              >
                <div>
                  <Avatar
                    size="20"
                    src={
                      value.ProfilePicture?.includes("http")
                        ? value.ProfilePicture
                        : images.placeholder_avatars[
                            parseInt(value.ProfilePicture)
                          ]
                    }
                    round={true}
                  />
                </div>
                <div className="participant_name">{value.UserName}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export default Participants;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	notification: {}
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {},
	extraReducers: {
		/**
		 * Store Notification - If App was Killed
		 */
		 StoreNotification: (state, action) => {
			try {
				return {
					...state,
					notification: action.payload.message,
				};
			} catch (error) {
				console.log(error);
			}
		}, 
        ClearNotification: (state, action) => {
            try {
				return {
					...state,
					notification: {},
				};
			} catch (error) {
				console.log(error);
			}
        }
	},
});

export const notifications = state => state.notifications;
export default notificationsSlice.reducer;
import { motion } from 'framer-motion';
import DropdownItem from './DropdownItem';

const variants = {
  open: {
    transition: { staggerChildren: 0.05 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export default function Dropdown(props) {
  return (
    <motion.ul variants={variants}>
      {props.dropdownItems.map((item, index) => (
        <DropdownItem
          key={index}
          item={item}
          activeItem={props.activeItem}
          name={String(props.dropdownItems[index])}
          onClick={props.onClick}
          cycleIsOpen={props.cycleIsOpen}
        />
      ))}
    </motion.ul>
  );
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	slots: []
};

export const slotsSlice = createSlice({
	name: 'slots',
	initialState,
	reducers: {},
	extraReducers: {
		addDate: (state, action) => {
			let date = action.payload;
			let store_slots = JSON.parse(JSON.stringify(state.slots));
			store_slots.push({
				date: date,
				times: []
			});
			return {
				...state,
				slots: store_slots,
			};
		}, 
		addTime: (state, action) => {
			let date = action.payload.date;
			let time = action.payload.time;
			let store_slots = JSON.parse(JSON.stringify(state.slots));
			store_slots.forEach(slot => {
				if (slot.date === date) {
					slot.times.push(time);
				}
			});
			return {
				...state,
				slots: store_slots,
			};
		},
		removeDate: (state, action) => {
			let date = action.payload;
			let store_slots = JSON.parse(JSON.stringify(state.slots));
			store_slots = store_slots.filter(val => val.date !== date)
			return {
				...state,
				slots: store_slots,
			};
		}, 
		removeTime: (state, action) => {
			let date = action.payload.date;
			let timeToRemove = action.payload.time;
			let store_slots = JSON.parse(JSON.stringify(state.slots));
			store_slots.forEach(slot => {
				if (slot.date === date) {
					let indexToRemove = slot.times.indexOf(timeToRemove);
					slot.times.splice(indexToRemove,1);
				}
			});
			return {
				...state,
				slots: store_slots,
			};
		}, 
		clearSlots: (state, action) => {
			return {
				...state,
				slots: [],
			};
		}
	},
});
export const slots = state => state.slots;
export default slotsSlice.reducer;
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { store } from "../store/store";

export default function ProtectedRoute() {
  const state = store.getState();
  const currentUser = state.currentUser;

  const navigate = useNavigate();

  if (!currentUser.isLogin) {
    navigate("/auth/login");
  }

  return (
    <>
      <Outlet />
    </>
  );
}

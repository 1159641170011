// default Type Action
export const Resolve = '/fulfilled';
export const Reject = '/';
export const Catch = '/';

// Login/Signup
export const GlobalAuth = 'GlobalAuth'; // works very well :)
export const LoginUser = 'LoginUser';
export const ReLoginUser = 'ReLoginUser';

// missing the firebase token here
export const CreateUser = 'CreateUser';

// list all meetings
export const GetUserData = 'GetUserData';
export const GetUserMeetings = 'GetUserMeetings';
export const ListMeetings = 'ListMeetings';

export const CreateNewMeeting = 'CreateNewMeeting';

export const JoinMyMeeting = 'JoinMyMeeting';
export const JoinInvite = 'JoinInvite';
export const GetMeetingStatus = 'GetMeetingStatus';
export const GetMeetingPlaces = 'GetMeetingPlaces';
export const CalculateCenterSearchPlaces = 'CalculateCenterSearchPlaces';
export const GetInvitation = 'GetInvitation';

export const UpdateMeeting = 'UpdateMeeting';

export const Vote = 'Vote';
export const EndVote = 'EndVote';
export const GetVotes = 'GetVotes';

export const CleanSubscriptions = 'CleanSubscriptions';
export const Resubscribe = 'Resubscribe';

export const DeleteAccount = 'DeleteAccount';
export const CheckAppVersion = 'CheckAppVersion';
export const FindPlaces = 'FindPlaces';

export const UploadProfilePic = 'UploadProfilePic';
export const UpdateMeetingPlaces = 'UpdateMeetingPlaces';

export const LeaveMeeting = 'LeaveMeeting';
export const DeleteMeeting = 'DeleteMeeting';

export const UpdateMeetingDetails = 'UpdateMeetingDetails';

export const UpdateMeetingSlots = 'UpdateMeetingSlots';

export const DateVote = 'DateVote';
export const GetDateVotes = 'GetDateVotes';
export const EndDateVote = 'EndDateVote';

export const UpdateGps = 'UpdateGps';

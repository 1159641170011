import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useClickOutsideRef } from "../../logic/hooks/useClickOutsideRef";

import "./overlay.scss";
import Overlay from "./Overlay";
import { ReactComponent as MeetUsCloseIcon } from "../../assets/icons/meetus-exit-icon.svg";

const containerVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
const modalVariant = {
  initial: { top: "45%" },
  isOpen: { top: "50%" },
  exit: { top: "45%" },
};

export default function OverlayContainer(props) {
  // handling clicks outside of modal
  const wrapperRef = useRef(null);
  // useClickOutsideRef(wrapperRef, props.setCreateMeetingModal);

  // handling progress
  const steps = [
    {
      id: "1",
      name: "Title & Agenda",
    },
    {
      id: "2",
      name: "Date & Time",
    },
    {
      id: "3",
      name: "Choose Location",
    },
    {
      id: "4",
      name: "Confirm and Create",
    },
  ];
  const [progress, setProgress] = useState(0);
  function nextStep() {
    setProgress(progress + 1);
    if (progress === steps.length) {
      setProgress(0);
    }
  }
  function prevStep() {
    setProgress(progress - 1);
  }

  return (
    <AnimatePresence mode="wait">
      {props.createMeetingModal && (
        <motion.div
          className="overlayContainer"
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={containerVariant}
        >
          <motion.div
            className="modalContainer"
            variants={modalVariant}
            ref={wrapperRef}
          >
            <Overlay
              toggleMeetingModal={props.toggleMeetingModal}
              createMeetingModal={props.createMeetingModal}
              steps={steps}
              progress={progress}
              nextStep={nextStep}
              prevStep={prevStep}
              setProgress={setProgress}
            />
            <div
              className="modal__closeButton"
              onClick={() => props.toggleMeetingModal()}
            >
              <MeetUsCloseIcon />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { Paths } from "./routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { store } from "../src/store/store";

import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ConfirmSignUp from "./pages/ConfirmSignUp";
import Dashboard from "./pages/Dashboard";
import Meetings from "./pages/Meetings";
import MeetingInvitation from "./pages/MeetingInvitation";
import LoginForm from "./components/forms/LoginForm";

import ProtectedRoute from "../src/routes/ProtectedRoute";

import { GlobalAuth } from "../src/store/actions/actionConstants";

export default function App() {
  console.log("starting app...");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/signup/confirm" element={<ConfirmSignUp />}></Route>
        <Route element={<ProtectedRoute />}>
          <Route path={Paths.Dashboard.path} element={<Dashboard />} />
          <Route path={Paths.Meetings.path} element={<Meetings />} />
          <Route
            path={Paths.MeetingInvitation.path}
            element={<MeetingInvitation />}
          />
        </Route>
        <Route path={Paths.Login.path} element={<Login />} />
      </Routes>
    </Router>
  );
}

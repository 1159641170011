import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ThreeDots } from "react-loader-spinner";
import { store } from "../../store/services";
import Network from "../../store/services";
import { GetUserData } from "../../store/actions/actionConstants";
import { GetUserMeetings } from "../../store/actions/actionConstants";

import Input from "./Input";
import Checkbox from "./Checkbox";
import SocialButton from "./SocialButton";
import Button from "../buttons/Button";
import "./loginForm.scss";
import { ReactComponent as LogoLight } from "../../assets/logo/logo-light.svg";
import { ReactComponent as MeetUsIcon } from "../../assets/icons/meetus-app-icon.svg";
import { ReactComponent as GoogleLogo } from "../../assets/icons/google-g-logo.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook-f-logo.svg";
import { useNavigate } from "react-router-dom";

import authUser from "../../utils/auth/authUser";
import getCredentials from "../../utils/auth/getCredentials";

export default function LoginForm(props) {
  // styling submit button based on form input
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [emailInput, setEmailInput] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  const [loading, setLoading] = useState(false);
  function detectEmailInput(e) {
    setEmailInput(e.target.value.toLowerCase());
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value.toLowerCase());
  }
  useEffect(() => {
    // navigate("/meetings");
    console.log("running conditional");
    console.log("emailInput", emailInput);
    console.log("passwordInput", passwordInput);
    if (emailInput !== "" && passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg100");
    } else if (emailInput !== "" || passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    } else {
      document.getElementById("loginSubmit").classList.remove("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    }
  });

  async function login() {
    setLoading(true);
    authUser(emailInput, passwordInput)
      .then(function (response) {
        console.log("login response: ", response);
        return getCredentials(response);
      })
      .then(function (response) {
        console.log("get Creds response:  ", response);
        response.Expiration = response.Expiration.toString(); // date type not possible in redux
        return dispatch({ type: "LoginUserSuccess", payload: response });
      })

      .then(function (response) {
        console.log("dispatch response: ", response);
        if (response.type === "LoginUserSuccess") {
          return dispatch(
            Network.networkCall({
              action: GetUserData,
              data: {
                user_id: emailInput,
              },
            })
          );
        } else {
          throw new Error("Could not retrieve keys.");
        }
      })
      .then(function (response) {
        console.log("GetUser data response: ", response);
        if (response.success === true) {
          if (response?.data?.Count > 0) {
            dispatch({
              type: "SetFirstOpen",
              payload: { firstOpen: "false" },
            });
            dispatch({
              type: "SetFirstMeetingCreated",
              payload: { firstMeetingCreated: "true" },
            });
            dispatch({
              type: "SetFirstMeetingJoined",
              payload: { firstMeetingJoined: "true" },
            });
          }

          return dispatch(
            Network.networkCall({
              action: GetUserMeetings,
              data: {
                user_id: emailInput,
              },
            })
          );
        } else {
          throw new Error("Could not retrieve userdata.");
        }
      })

      .then(function (response) {
        console.log("Get User data response: ", response);
        if (response.success === true) {
          navigate("/meetings");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error logging in", error);
        setLoading(false);
        return;
      });
  }

  function googleClick() {
    alert("clicked Google Button!");
  }
  function facebookClick() {
    alert("clicked Facebook Button!");
  }
  return (
    <div className="loginFormContainer">
      <LogoLight className="logo--light" />
      <h2>This is meetUs web in the first version</h2>
      <p>You can still find our Android/iOS Apps here:</p>
      <a href="https://play.google.com/store/apps/details?id=com.eudaitec.meetusapp&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          style={{ width: 100 + "%" }}
          className="storeIconGoogle"
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
      <a href="https://apps.apple.com/us/app/meetusapp/id1601812533">
        <img
          style={{ width: 100 + "%" }}
          className="storeIconApple"
          alt="Get it on Apple Store"
          src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png"
        />
      </a>
      <form id="loginForm">
        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
        <Input
          type="password"
          inputId="password"
          required="required"
          labelId="loginPasswordLabel"
          labelString="PASSWORD"
          detectInput={detectPasswordInput}
        />
      </form>
      {/* <div className="socialContainer">
        <SocialButton
          icon={<GoogleLogo />}
          buttonClick={googleClick}
          className="google"
          border="1px solid #F2F2F2"
        />
        <SocialButton
          icon={<FacebookLogo />}
          buttonClick={facebookClick}
          className="facebook"
          border="none"
        />
        <SocialButton
          icon={'Guest'}
          buttonClick={props.getToken}
          className="guest"
          border="none"
        />
      </div> */}
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ED3424"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={loading}
      />
      {loading ? null : (
        <Button
          buttonClick={login}
          width={180}
          className={"login"}
          name={"login"}
        />
      )}
      <div className="checkboxContainer">
        <Checkbox labelText="Stay signed in" />
      </div>

      <button type="submit" form="loginForm" id="loginSubmit">
        <MeetUsIcon />
      </button>
      <a href="#test">Can't sign in?</a>
      <a href="/signup">Create Account</a>
    </div>
  );
}

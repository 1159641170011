import star from "../../assets/img/star.jfif";
import moment from "moment";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
  exit,
} from "../../assets/images";
import "./cards.scss";

export default function CardPlace(props) {
  // console.log("What I have for the place card: ", props.place);

  return (
    <>
      <div className="card">
        <div className="card__top">
          <div
            className="card_close_button"
            onClick={() => {
              let places = { ...props.meetingPlace };

              console.log("trying to kill it: ", props.place.place_ID, places);

              delete places[props.place.place_ID];
              console.log("trying to kill it2: ", places);
              props.setMeetingPlace(places);
              console.log("getting here: ", places);
            }}
          >
            <img className="closePlaceButton" src={cancel} alt={""} />
          </div>
          <img
            src={
              props.place?.place_image_link
                ? props.place?.place_image_link
                : "https://picsum.photos/200/300/?blur"
            }
            alt={""}
          />
          <div className="rating__pan">
            <img className="rating__pan__star__img" src={star} alt="star" />
            <span>{props.place?.place_rating}</span>
          </div>
        </div>
        <div className="bottom__banner">
          <h3 className="card__meetingName">{props.place?.place_title}</h3>

          <div>
            {props.place?.location_address ? (
              <div className="location__name">
                {props.place?.location_address + "\n"}
              </div>
            ) : null}
            {props.place?.place_tel ? (
              <div className="location__name">
                {props.place?.place_tel + "\n"}
              </div>
            ) : null}
            {props.place?.place_website ? (
              <div className="location__name">
                {props.place?.place_website + "\n"}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

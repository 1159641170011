import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/localStorage";
import { getWSService } from "../../utils/webSocket";

const initialState = {
  chats: [],
  connections: [],
  offline_msgs: [],
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    PullChatSuccess: (state, action) => {
      let msg = action.payload.message;
      let chats = JSON.parse(JSON.stringify(state.chats));
      let isOldChatMsg = false;
      if (chats.length > 0) {
        chats.forEach((item) => {
          if (item.meeting_id === msg.m_id) {
            msg.chat = filterDuplicateServerMsgs(msg.chat, item.chat);
            let local_only = getLocalOnlyMessages(item.chat);
            item.chat = item.chat.filter(
              (ar) => !local_only.find((rm) => rm.id === ar.id)
            );
            let not_found_locals = matchedServerMessages(local_only, msg.chat);
            item.chat = [...item.chat, ...not_found_locals];
            item.chat = [...new Set([...item.chat, ...msg.chat])];
            item.page = msg.page;
            if (msg?.last_msg) {
              item.last_msg = true;
            }
            isOldChatMsg = true;
          }
        });

        if (isOldChatMsg) {
          // replace the current chats object with updatd chat
          Storage.setJson("@chats", chats);
          return {
            ...state,
            chats: chats,
          };
        }
      }

      if ((chats.length > 0 && isOldChatMsg === false) || chats.length === 0) {
        chats.push({
          meeting_id: msg.m_id,
          chat: msg.chat,
          c_time: msg.c_time,
          ...(msg.last_msg && { last_msg: msg.last_msg }),
          page: msg.page,
        });

        // replace the current chats object with the updatd chat
        Storage.setJson("@chats", chats);
        return {
          ...state,
          chats: chats,
        };
      }
    },
    /**
     * Dispatched when a websocket message is received
     * in the background or foreground
     */
    ReceivedChatSuccess: (state, action) => {
      let msg = action.payload.message;
      let loadedChats = JSON.parse(JSON.stringify(state.chats));
      loadedChats.forEach((chat) => {
        if (chat.meeting_id === msg.m_id) {
          chat.chat.push(msg);
        }
      });

      // replace the current chats object with the updatd chat
      Storage.setJson("@chats", loadedChats);
      return {
        ...state,
        chats: loadedChats,
      };
    },
    /**
     * Dispatched when a chat message is sent by
     * the user himself, in this case ReceivedChatSuccess
     * won't be called as user is not expected to receive
     * his own sent message. We rather store it locally
     * by updating the state right away
     */
    LocalChatSuccess: (state, action) => {
      let msg = action.payload.message;
      let loadedChats = JSON.parse(JSON.stringify(state.chats));
      loadedChats.forEach((chat) => {
        if (chat.meeting_id === msg.m_id) {
          chat.chat.push(msg);
        }
      });
      // replace the current chats object with the updatd chat
      Storage.setJson("@chats", loadedChats);
      return {
        ...state,
        chats: loadedChats,
      };
    },
    /**
     * Dispatched when meetings are successfully
     * mapped with the connectionId issued to the
     */
    SetConnections: (state, action) => {
      let connections = action.payload.message;
      console.log("Stored Connections");
      // replace the current chats object with the updatd chat
      Storage.setJson("@connections", connections.meetings);
      return {
        ...state,
        connections: connections.meetings,
      };
    },
    /**
     * Dispatched when the Connections are acknowledged
     * after websocket reconnect - SendOfflineMessages
     */
    ClearOfflineMessages: (state, action) => {
      console.log("Cleared Offline stored Connections");
      Storage.setJson("@offline_msgs", []);
      Storage.set("@send_offline", "true");
      return {
        ...state,
        offline_msgs: [],
      };
    },
    /**
     * Dispatched when the Websocket is found as closed
     */
    ClearConnections: (state, action) => {
      console.log("Cleared stored Connections");
      Storage.set("@send_offline", "true");
      Storage.setJson("@connections", []);
      return {
        ...state,
        connections: [],
      };
    },
    /**
     * Dispatched when the websocket is not connected
     * and any message sending attempt is made
     * except chat messages
     */
    StoreOfflineMessage: (state, action) => {
      let msg = action.payload?.message;
      if (msg === undefined) {
        msg = action.payload?.data;
      }

      let offlineMessages = JSON.parse(JSON.stringify(state.offline_msgs));
      offlineMessages.push(msg);

      const notNil = (i) => !(typeof i === "undefined" || i === null);
      const filterBlanks = offlineMessages.filter(notNil);

      const ids = filterBlanks.map((o) => o?.routeKey);
      const filteredOfflineMessages = filterBlanks.filter(
        ({ routeKey }, index) => !ids.includes(routeKey, index + 1)
      );

      Storage.setJson("@offline_msgs", filteredOfflineMessages);
      return {
        ...state,
        offline_msgs: filteredOfflineMessages,
      };
    },
    /**
     * Dispatched when the connection is reset
     */
    SendOfflineMessages: (state, action) => {
      try {
        let offlineMessages = JSON.parse(JSON.stringify(state.offline_msgs));
        for (let index = 0; index < offlineMessages.length; index++) {
          if (
            offlineMessages[index] !== null &&
            (offlineMessages[index]?.message?.length > 0 ||
              offlineMessages[index]?.message?.msg !== undefined)
          ) {
            setTimeout(async function () {
              getWSService().sendMessage(
                offlineMessages[index].routeKey,
                offlineMessages[index].message,
                false,
                true
              );
            }, 500);
          }
        }
      } catch (error) {
        console.log("Error ", error);
      }
    },
    /**
     * Dispatched when the offline messages are loaded from async storage
     */
    OfflineMessagesLoader: (state, action) => {
      let off_msgs = [];
      off_msgs.push(action.payload.message);
      return {
        ...state,
        offline_msgs: off_msgs,
      };
    },
    /**
     * Dispatched when the chats are loaded from async storage
     */
    ChatsLoader: (state, action) => {
      return {
        ...state,
        chats: action.payload,
      };
    },
    /**
     * Dispatched when connections are loaded from async storage
     */
    ConnectionsLoader: (state, action) => {
      return {
        ...state,
        connections: action.payload,
      };
    },
    /**
     * Dispatched when logout or delete account is clicked
     */
    LogoutChatReset: (state, action) => {
      return {
        chats: [],
        connections: [],
        offline_msgs: [],
      };
    },
    /**
     * Dispatched when an offline message is sent
     */
    OfflineMessageReset: (state, action) => {
      try {
        let msg = action.payload.message;
        let loadedChats = JSON.parse(JSON.stringify(state.chats));
        loadedChats.forEach((chat) => {
          if (chat.meeting_id === msg.m_id) {
            chat.chat.forEach((chatMsg) => {
              if (chatMsg.id === msg.id) {
                chatMsg.isOffline = false;
              }
            });
          }
        });

        // replace the current chats object with the updatd chat
        Storage.setJson("@chats", loadedChats);
        return {
          ...state,
          chats: loadedChats,
        };
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Dispatched when an reported message is sent
     */
    UpdateReportedMessages: (state, action) => {
      try {
        let msg = action.payload.message;
        let loadedChats = JSON.parse(JSON.stringify(state.chats));
        loadedChats.forEach((chat) => {
          if (chat.meeting_id === msg.msg[0].m_id) {
            chat.chat.forEach((chatMsg) => {
              msg.msg.forEach((m) => {
                if (chatMsg.id === m.id) {
                  chatMsg.reported = m.reported;
                  chatMsg.reporter_id = m.reporter_id;
                }
              });
            });
          }
        });

        // replace the current chats object with the updatd chat
        Storage.setJson("@chats", loadedChats);
        return {
          ...state,
          chats: loadedChats,
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
  extraReducers: {},
});

function matchedServerMessages(local_chats, server_chats) {
  for (var i = 0, len = local_chats.length; i < len; i++) {
    for (var j = 0, len2 = server_chats.length; j < len2; j++) {
      if (local_chats[i]?.msg === server_chats[j]?.msg) {
        local_chats.splice(i, 1);
        len2 = server_chats.length;
      }
    }
  }
  return local_chats;
}

function filterDuplicateServerMsgs(server_chats, local_chats) {
  for (var i = 0, len = server_chats.length; i < len; i++) {
    for (var j = 0, len2 = local_chats.length; j < len2; j++) {
      if (server_chats[i]?.id === local_chats[j]?.id) {
        server_chats.splice(i, 1);
        len2 = local_chats.length;
      }
    }
  }
  return server_chats;
}

function getLocalOnlyMessages(local_chats) {
  let local_only = [];
  for (var i = 0, len = local_chats.length; i < len; i++) {
    if (isLocalMessage(local_chats[i].id)) {
      local_only.push(local_chats[i]);
    }
  }
  return local_only;
}

function isLocalMessage(id) {
  if (isNumeric(id)) {
    return true;
  } else {
    return false;
  }
}

function isNumeric(str) {
  return !isNaN(str);
}

export const chats = (state) => state.chats;
export default chatsSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";

import { store } from "../../store/store";

import "./calendar.scss";
import Calendar from "./Calendar";
import DropdownContainer from "../dropdown/DropdownContainer";

import moment from "moment";

export default function CalendarContainer(props) {
  // const state = store.getState();
  const meetings = useSelector((state) => state.meetings.meetings);
  // console.log('rendering CalendarContainer');
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const date = new Date();
  // const [currentDate, setCurrentDate] = useState();
  // const [currentDay, setCurrentDay] = useState();
  // const [currentDayOfWeek, setCurrentDayOfWeek] = useState();
  // const [currentMonth, setCurrentMonth] = useState();
  // const [currentSumOfDaysInMonth, setCurrentSumOfDaysInMonth] = useState();
  const [currentArrDaysInMonth, setCurrentArrDaysInMonth] = useState([]);
  const [currentArrDayNames, setCurrentArrDayNames] = useState([]);
  const [currentArrDates, setCurrentArrDates] = useState([]);
  // const [currentYear, setCurrentYear] = useState();
  const [currentArrYears, setCurrentArrYears] = useState([]);
  const [activeYear, setActiveYear] = useState(date.getFullYear());
  const [activeMonth, setActiveMonth] = useState(months[date.getMonth()]);
  const [activeDay, setActiveDay] = useState(date.getDate());

  // function to create arrays of daysInMonth and dayNamesInMonth
  const createDaysArr = (
    arrOfDatesInMonth,
    sumOfDaysInMonth,
    arrOfDaysInMonth,
    arrOfDayNamesInMonth,
    activeYear,
    activeMonth
  ) => {
    for (let i = 0; i < sumOfDaysInMonth; i++) {
      var d = new Date(activeYear, months.indexOf(activeMonth), i + 1);
      var dayName = days[d.getDay()];

      let day = {
        day: i + 1,
        date: moment(d).format("DD.MM.YYYY"),
        dayName: dayName,
        meeting: [],
      };
      arrOfDatesInMonth.push(day);
      arrOfDaysInMonth.push(i + 1);
    }
    for (let h = 1; h <= sumOfDaysInMonth; h++) {
      var d = new Date(activeYear, months.indexOf(activeMonth), h);
      var dayName = days[d.getDay()];
      arrOfDayNamesInMonth.push(dayName);
    }
  };

  // function to create array of years for dropdown
  const createArrOfYears = (arrOfYears) => {
    let max = new Date().getFullYear() + 10;
    let min = max - 11;
    for (let i = min; i <= max; i++) {
      arrOfYears.push(i);
    }
  };

  // get initial dates
  const initializeDates = () => {
    // get date, sumOfDaysInMoth and run functions to create
    // arrays of daysInMonth, dayNamesInMonth and years
    const date = new Date();
    let arrOfDaysInMonth = [];
    let arrOfDayNamesInMonth = [];
    let sumOfDaysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    let arrOfYears = [];
    let arrOfDatesInMonth = [];
    createDaysArr(
      arrOfDatesInMonth,
      sumOfDaysInMonth,
      arrOfDaysInMonth,
      arrOfDayNamesInMonth,
      activeYear,
      activeMonth
    );
    createArrOfYears(arrOfYears);

    // setCurrentDate(date);
    // setCurrentDay(date.getDate());
    // setCurrentDayOfWeek(days[date.getDay()]);
    // setCurrentMonth(months[date.getMonth()]);
    // setCurrentSumOfDaysInMonth(sumOfDaysInMonth);

    setCurrentArrDates(arrOfDatesInMonth);
    setCurrentArrDaysInMonth(arrOfDaysInMonth);
    setCurrentArrDayNames(arrOfDayNamesInMonth);
    // setCurrentYear(date.getFullYear());
    setCurrentArrYears(arrOfYears);
  };
  useEffect(() => {
    initializeDates();
  }, [meetings]);

  useEffect(() => {
    currentArrDates.forEach(function (date, index) {
      Object.values(meetings).forEach(function (meeting) {
        if (
          date.date ===
          moment(new Date(meeting.MeetingTime)).format("DD.MM.YYYY")
        ) {
          console.log(
            "here is the meeting and ID: ",
            meeting.MeetingID,
            currentArrDates[index].meeting
          );
          if (meeting.archieved != true) {
            currentArrDates[index].meeting[meeting.MeetingID] = meeting;
          }
        }
      });
    });
    console.log("days array: ", currentArrDates);
  }, [currentArrDates, meetings, activeMonth]);

  // get active dates
  const getActiveDates = () => {
    // set activeDates, sumOfDaysInMoth and run functions to create
    // arrays of daysInMonth, dayNamesInMonth
    let arrOfDaysInMonth = [];
    let arrOfDayNamesInMonth = [];
    let sumOfDaysInMonth = new Date(
      activeYear,
      months.indexOf(activeMonth) + 1,
      0
    ).getDate();
    let arrOfDatesInMonth = [];
    createDaysArr(
      arrOfDatesInMonth,
      sumOfDaysInMonth,
      arrOfDaysInMonth,
      arrOfDayNamesInMonth,
      activeYear,
      activeMonth
    );
    // setCurrentSumOfDaysInMonth(sumOfDaysInMonth);
    setCurrentArrDates(arrOfDatesInMonth);
    setCurrentArrDaysInMonth(arrOfDaysInMonth);
    setCurrentArrDayNames(arrOfDayNamesInMonth);
  };
  useEffect(() => {
    getActiveDates();
  }, [activeYear, activeMonth]);

  // console.log('currentDate: ', currentDate);
  // console.log('currentDay: ', currentDay);
  // console.log('currentDayOfWeek: ', currentDayOfWeek);
  // console.log('currentMonth: ', currentMonth);
  // console.log('currentSumOfDaysInMonth: ', currentSumOfDaysInMonth);
  // console.log('currentArrDaysInMonth: ', currentArrDaysInMonth);
  // console.log('currentArrDayNames: ', currentArrDayNames);
  // console.log('currentYear: ', currentYear);
  // console.log('currentArrYears: ', currentArrYears);
  // console.log('activeYear: ', activeYear);
  // console.log('activeMonth: ', activeMonth);
  // console.log('activeDay: ', activeDay);

  // setting active year
  const setActiveYearOnClick = (year) => {
    setActiveYear(year);
  };
  // setting active month
  const setActiveMonthOnClick = (month) => {
    setActiveMonth(month);
  };
  // setting active day
  const setActiveDayOnClick = (day) => {
    setActiveDay(day);
  };

  // scroll to active day on first render
  const dayRef = useRef([]);
  // console.log('dayRef: ', dayRef);
  const addToRefs = (el) => {
    dayRef.current.push(el);
    // console.log('dayRef.current.length: ', dayRef.current.length);
    // console.log('activeDay: ', activeDay);
    if (dayRef.current.length === activeDay) {
      dayRef.current[activeDay - 1].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // console.log('executing scroll');
    }
    // console.log('dayRef.current[activeDay]: ', dayRef.current[activeDay]);
  };
  // const resetDayRef = () => {
  //   console.log('running resetDayRef');
  //   dayRef.current = [];
  // };

  return (
    <>
      <div className="calendar__dropdownContainer">
        <DropdownContainer
          activeItem={activeYear}
          heightFactor={34}
          width={"40%"}
          dropdownItems={currentArrYears}
          onClick={setActiveYearOnClick}
        />
        <DropdownContainer
          activeItem={activeMonth}
          heightFactor={34}
          width={"60%"}
          dropdownItems={months}
          onClick={setActiveMonthOnClick}
        />
      </div>
      <Scrollbars thumbSize={100}>
        <Calendar
          activeMonth={activeMonth}
          setShowMeeting={props.setShowMeeting}
          setDetailedMeeting={props.setDetailedMeeting}
          currentArrDates={currentArrDates}
          currentArrDaysInMonth={currentArrDaysInMonth}
          currentArrDayNames={currentArrDayNames}
          activeDay={activeDay}
          setActiveDayOnClick={setActiveDayOnClick}
          dayRef={dayRef}
          addToRefs={addToRefs}
        />
      </Scrollbars>
    </>
  );
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ThreeDots } from "react-loader-spinner";
import { store } from "../../store/store";

import Network from "../../store/services";
import { GetUserData } from "../../store/actions/actionConstants";
import { CreateUser } from "../../store/actions/actionConstants";

import resendToken from "../../utils/auth/forgotPassword/resendToken";
import confirmSignUp from "../../utils/auth/confirmSignUp";

import Input from "./Input";
import Checkbox from "./Checkbox";
import SocialButton from "./SocialButton";
import Button from "../buttons/Button";
import "./loginForm.scss";
import { ReactComponent as LogoLight } from "../../assets/logo/logo-light.svg";
import { ReactComponent as MeetUsIcon } from "../../assets/icons/meetus-app-icon.svg";
import { ReactComponent as GoogleLogo } from "../../assets/icons/google-g-logo.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook-f-logo.svg";
import { useNavigate } from "react-router-dom";

import signUp from "../../utils/auth/signUp";

import authUser from "../../utils/auth/authUser";
import getCredentials from "../../utils/auth/getCredentials";

export default function ConfirmSignUpForm(props) {
  // styling submit button based on form input
  const state = store.getState();
  const dispatch = useDispatch();
  const currentUser = state.currentUser;
  let navigate = useNavigate();
  const [emailInput, setEmailInput] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(false);

  const [loading, setLoading] = useState(false);

  function detectEmailInput(e) {
    setEmailInput(e.target.value.toLowerCase());
  }
  function detectConfirmationCode(e) {
    setConfirmationCode(e.target.value.toLowerCase());
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasWhiteSpace(s) {
    return s.indexOf(" ");
  }
  async function checkCodeInput() {
    if (!confirmationCode || confirmationCode === "") {
      return false;
    }
    if (hasWhiteSpace(confirmationCode) >= 0) {
      return false;
    }

    return true;
  }

  async function checkMailInput() {
    if (!emailInput || emailInput === "") {
      return false;
    }
    if (hasWhiteSpace(emailInput) >= 0) {
      return false;
    }
    const checkMail = validateEmail(emailInput);
    if (!checkMail) {
      return false;
    }
    return true;
  }

  async function confirmAndLogin(code) {
    setLoading(true);

    // crashlytics().log('Confirm-SignUp API Call.');
    // await Promise.all([crashlytics().setUserId(currentUser.userID)]);

    let token = "";
    //  Confirm User
    confirmSignUp(
      confirmationCode ? confirmationCode : code,
      currentUser.userName ? currentUser.userName : emailInput
    )
      .then(function (response) {
        console.log("Confirmation response: ", response);
        console.log(
          "trying to login : ",
          (currentUser.userName ? currentUser.userName : emailInput) +
            "//" +
            (currentUser.tempPw = currentUser.tempPw)
        );
        return authUser(
          currentUser.userName ? currentUser.userName : emailInput,
          (currentUser.tempPw = currentUser.tempPw)
        );
      })
      .catch((err) => {
        console.log("confirmSignUp response: ", err);
        console.log("retrying confirmSignUp");
        // crashlytics().log('Retrying Confirm-SignUp API Call.');
        // crashlytics().recordError(err);
        return confirmSignUp(
          confirmationCode,
          currentUser.userName ? currentUser.userName : emailInput
        );
      })
      .then(function (response) {
        token = response;
        console.log("login response: ", response);
        return getCredentials(response);
      })
      .catch((err) => {
        console.log("getCredentials response: ", err);
        console.log("retrying getcreds for signing");
        // crashlytics().log('Retrying Get-Credentials API Call.');
        // crashlytics().recordError(err);
        return getCredentials(token);
      })
      .then(function (response) {
        console.log("get Creds response:  ", response);
        response.Expiration = response.Expiration.toString(); // date type not possible in redux
        return dispatch({ type: "LoginUserSuccess", payload: response });
      })
      .then(function (response) {
        console.log("First dispatch response: ", response);
        console.log("trying to dispatch");
        let endpoint = "";

        if (currentUser.guestStatus === "true") {
          endpoint = currentUser.userARN;
        } else {
          endpoint = "";
        }

        return dispatch(
          Network.networkCall({
            action: CreateUser,
            data: {
              guest_status: "false",
              user_name: currentUser.email ? currentUser.email : emailInput,
              user_firstname: currentUser.firstName,
              user_email: currentUser.email ? currentUser.email : emailInput,
              token: currentUser.fcmToken,
              user_familyname: currentUser.lastName,
              user_nickname: currentUser.nickName,
              profile_picture: currentUser.profilePic,
              endpoint,
            },
          })
        );
      })
      .then((response) => {
        if (response.success === true) {
          setLoading(false);
          Storage.set("@tempPw", ""); // delete tempPw
          console.log("Create User Response: ", response);
          // analytics().logEvent('signup_confirm');

          // let hasGotJoinLink = false,
          // 	meeting_id;

          // if (!isEmpty(links)) {
          // 	links.forEach(link => {
          // 		if (getLinkType(link) === 'link') {
          // 			hasGotJoinLink = true;
          // 			const url = new URL(link);
          // 			meeting_id = url.pathname.split('/').slice(1)[1];
          // 		}
          // 	});
          // 	store.dispatch({
          // 		type: 'clearForgotPassAndConfirmationLinks',
          // 		payload: null,
          // 	});
          // 	store.dispatch({type: 'setJoinMeetingId', payload: meeting_id});
          // }

          setLoading(false);

          // if (hasGotJoinLink) {
          // 	navigation.navigate('AuthorizedNavigator', {
          // 		screen: SCREEN_MEETING_INVITATION,
          // 	});
          // } else {
          // 	navigation.navigate('AuthorizedNavigator', {
          // 		screen: SCREEN_MEETINGS,
          // 	});
          // }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        // crashlytics().recordError(err);
        console.log(err);
      });
  }

  function googleClick() {
    alert("clicked Google Button!");
  }
  function facebookClick() {
    alert("clicked Facebook Button!");
  }
  return (
    <div className="loginFormContainer">
      <LogoLight className="logo--light" />
      <h2>Confirm your Signup</h2>
      <p>You should have a mail with the code by now, please enter it below.</p>

      <form id="loginForm">
        <Input
          type="code"
          inputId="confirmCode"
          required="required"
          labelId="confirmCode"
          labelString="ENTER CODE"
          detectInput={detectConfirmationCode}
        />

        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
      </form>
      {/* <div className="socialContainer">
        <SocialButton
          icon={<GoogleLogo />}
          buttonClick={googleClick}
          className="google"
          border="1px solid #F2F2F2"
        />
        <SocialButton
          icon={<FacebookLogo />}
          buttonClick={facebookClick}
          className="facebook"
          border="none"
        />
        <SocialButton
          icon={'Guest'}
          buttonClick={props.getToken}
          className="guest"
          border="none"
        />
      </div> */}
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ED3424"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={loading}
      />
      {loading ? null : (
        <Button
          buttonClick={""}
          width={180}
          className={"confirm"}
          name={"confirm"}
        />
      )}

      <button type="submit" form="loginForm" id="loginSubmit">
        <MeetUsIcon />
      </button>
      <a href="#test">Can't sign in?</a>
      <a href="/signup">Create Account</a>
    </div>
  );
}

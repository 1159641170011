import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './mobileMenu.scss';

import MenuItem from './MenuItem';
import { ReactComponent as MenuIcon } from './hamburger_menu.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Alarm } from './alarm.svg';

export default function MobileMenu() {
  // display route name
  const location = useLocation();
  let routeName = location.pathname.substring(1);
  routeName = routeName.charAt(0).toUpperCase() + routeName.slice(1);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const navItems = [
    {
      name: 'Dashboard',
      to: '/dashboard',
      // icon: <Calendar className="logo"/>,
    },
    {
      name: 'Meetings',
      to: '/meetings',
      // icon: <Calendar className="logo"/>,
    },
    {
      name: 'Profile',
      to: '/profile',
      // icon: <Calendar className="logo"/>,
    },
    {
      name: 'Sign In',
      to: '/signin',
      // icon: <Filter className="logo"/>,
    },
    {
      name: 'Sign Up',
      to: '/signup',
      // icon: <Filter className="logo"/>,
    },
  ];

  return (
    <>
      <div className={click ? 'sidebar__menu active' : 'sidebar__menu'}>
        <ul>
          {navItems.map((item) => {
            return (
              <MenuItem
                key={item.name}
                name={item.name}
                to={item.to}
                // icon={item.icon}
                closeMobileMenu={closeMobileMenu}
              />
            );
          })}
        </ul>
      </div>
      <header>
        <div className="header__container">
          <div className="header__menu" onClick={handleClick}>
            <MenuIcon className="header__menuButtton" />
            <h2 className="header__title">{routeName}</h2>
          </div>
          <div className="header__options">
            <a href="#">
              <Alarm className="header__notifications" />
            </a>
            <a href="#">
              <Logo className="header__profile" />
            </a>
          </div>
        </div>
      </header>
    </>
  );
}

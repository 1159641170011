import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AmazonCognitoIdentity,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function authUser(username, password) {
  var authenticationData = {
    Username: username,
    Password: password,
  };

  var authenticationDetails = new AuthenticationDetails(authenticationData);

  var userData = {
    Username: username,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => resolve(result.getIdToken().getJwtToken()),
      onFailure: (err) => reject(err),
    })
  );
}

export default authUser;

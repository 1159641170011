import './imgSlider.scss';

export default function ImgSlider(props) {
  return (
    <div className="loginImgContainer">
      <div className="login__Crossfade">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

// import { useState } from 'react';
import "./checkbox.scss";

export default function Checkbox(props) {
  // const [isChecked, setIsChecked] = useState(false);
  // function handleCheckboxChange(event) {
  //   setIsChecked({ checked: event.target.checked });
  // }
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id="remainLoggedIn"
        name="remainLoggedIn"
        value="remainLoggedIn"
        checked={props.checked}
        onChange={() => props.onChange((prev) => !prev)}
      />
      <label htmlFor="remainLoggedIn">{props.labelText}</label>
    </div>
  );
}

import axios from "axios";

// axios post with async/await
const url =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
  process.env.REACT_APP_FIREBASE_WEB_API_KEY;
const join_link = process.env.REACT_APP_join_link;
const androidPackageName = process.env.REACT_APP_androidPackage;
const iosBundleId = process.env.REACT_APP_bundleId;

export default async function createShortLink(newMeetingID) {
  const body = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://" + join_link,
      link: "https://meetus.app/link/" + newMeetingID,
      androidInfo: {
        androidPackageName: androidPackageName,
      },
      iosInfo: {
        iosBundleId: iosBundleId,
      },
    },
  };
  console.log("this is the body request that I send: ", body);
  try {
    const resp = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("axios post request: ", resp.data);
    return resp.data.shortLink;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return false;
  }
}

import { useEffect, useState } from "react";
import "./changeOverlay.scss";
import { useDispatch, useSelector } from "react-redux";
import Network from "../../store/services";
import {
  CreateNewMeeting,
  JoinMyMeeting,
} from "../../store/actions/actionConstants";
import { Scrollbars } from "react-custom-scrollbars";

import { motion, AnimatePresence } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";

import ProgressMeter from "../progressMeter/ProgressMeter";
import Input from "../forms/Input";
import InputMultiLine from "../forms/InputMultiLine";
import OccasionsContainer from "../occasions/OccasionsContainer";
import PickerContainer from "../picker/PickerContainer";
import Button from "../buttons/Button";
import TimePicker from "../picker/TimePicker";
import Checkbox from "../forms/Checkbox";

import Map from "../map/Map";
import MapAutocomplete from "../map/MapAutocomplete";
import Autocomplete from "../map/Autocomplete";
import CalendarPicker from "../forms/CalendarPicker";
import CardPlace from "../cards/CardPlace";
import ChangeMeetingDetails from "./ChangeMeetingDetails";
// import TimePicker from "../forms/TimePicker";
import { store } from "../../store/store";

import {
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
  ViberShareButton,
  ViberIcon,
  TelegramIcon,
  TelegramShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
} from "react-share";

import moment from "moment";
import createShortLink from "../../logic/api/firebaseCreateLink";
// import { FirebaseDynamicLinks } from "firebase-dynamic-links";

export default function ChangeMeetingOverlay(props) {
  // console.log("app Progress: ", props.progress);
  console.log("this is the meeting data: ", props.meeting);
  const state = store.getState();
  const currentUser = state.currentUser;
  const dispatch = useDispatch();

  const [meetingID, setMeetingID] = useState(
    props.meeting?.MeetingID ? props.meeting?.MeetingID : ""
  );
  const [meetingTitle, setMeetingTitle] = useState(
    props.meeting?.MeetingTitle ? props.meeting?.MeetingTitle : ""
  );
  const [searchPlace, setSearchPlace] = useState();
  const [meetingDescription, setMeetingDescription] = useState(
    props.meeting?.MeetingDescription ? props.meeting?.MeetingDescription : ""
  );

  const [isMidpoint, setIsMidpoint] = useState(false);
  const [isOpenDated, setIsOpenDated] = useState(false);

  const [meetingTime, setMeetingTime] = useState(
    props.meeting?.MeetingTime ? props.meeting?.MeetingTime : ""
  );
  const [meetingEnd, setMeetingEnd] = useState(
    props.meeting?.MeetingEnd ? props.meeting?.MeetingEnd : ""
  );
  const [meetingPlace, setMeetingPlace] = useState(
    props.meeting?.MeetingPlace ? props.meeting?.MeetingPlace : {}
  );

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(
    props.meeting?.MeetingTime
      ? moment(props.meeting?.MeetingTime).format("HH:mm")
      : "10:00"
  );
  const [endTime, setEndTime] = useState(
    props.meeting?.MeetingTime
      ? moment(props.meeting?.MeetingTime).format("HH:mm")
      : "10:00"
  );

  const [changing, setChanging] = useState(false);

  const [meetingLink, setMeetingLink] = useState();
  const [webMeetingLink, setWebMeetingLink] = useState();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(new Date(meetingTime));

  const [changeTime, setChangeTime] = useState(false);
  const [changePlace, setChangePlace] = useState(false);
  // console.log("StartTime: ", meetingTime, " EndTime: ", meetingEnd);
  // console.log("this is the meeting Link: ", meetingLink);
  // console.log("meetingPlace: ", meetingPlace);
  // console.log("is Midpoint: ", isMidpoint);
  // console.log("is isOpenDated: ", isOpenDated);

  console.log("this is the meeting Title: ", meetingTitle);

  function resetValues() {
    setMeetingTitle();
    setSearchPlace();
    setMeetingDescription();
    setIsMidpoint(false);
    setIsOpenDated(false);
    setMeetingTime();
    setMeetingEnd();
    setDate();
    setStartTime();
    setEndTime();
    setMeetingLink();
    setWebMeetingLink();
    setSelected();
    props.setProgress(0);
    console.log("reseting values");
  }
  useEffect(() => {
    setMeetingPlace(meetingPlace);
  }, [meetingPlace]);

  useEffect(() => {
    resetValues();
  }, [props.createMeetingModal]);

  const sharedMessage =
    "Hi, I would like you to join this link and then join my meeting via meetUs.\n\n This is a Link for the browser:\n" +
    webMeetingLink +
    "\n" +
    " \n\n And here is the mobile link: \n" +
    meetingLink;

  async function createDynamicLink() {
    const link = await createShortLink(newMeetingID);
    setMeetingLink(link);

    setWebMeetingLink("https://meetus.app/invitation/" + newMeetingID);
  }

  useEffect(() => {
    let newDate = moment(selected).format("YYYY-MM-DD") + " " + startTime;
    setMeetingTime(newDate);
  }, [selected, startTime]);

  useEffect(() => {
    let newDate;
    if (startTime < endTime) {
      newDate = moment(selected).format("YYYY-MM-DD") + " " + endTime;
    } else {
      newDate =
        moment(selected).add(1, "d").format("YYYY-MM-DD") + " " + endTime;
    }
    setMeetingEnd(newDate);
  }, [endTime]);

  let newMeetingID = "",
    newMeetingTitle = "",
    newMeetingTopicArn = "";

  const contentAnim = {
    initial: {
      opacity: 0,
      left: "20%",
    },
    isOpen: {
      opacity: 1,
      left: "0%",
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    exit: { opacity: 0, left: "-20%" },
  };
  return (
    <>
      <h3>Change Meeting</h3>
      <ProgressMeter
        width="70%"
        steps={props.steps}
        progress={props.progress}
      />
      <div className="modalContentContainer">
        {/* Step 1 */}
        <AnimatePresence mode="wait">
          {props.progress === 0 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={"initial"}
              animate={"isOpen"}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={"exit"}
            >
              <div>
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#ED3424"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={loading}
                />
                {loading ? null : (
                  <ChangeMeetingDetails
                    meeting={props.meeting}
                    changing={changing}
                    setChanging={setChanging}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    selected={selected}
                    setSelected={setSelected}
                    setChangePlace={setChangePlace}
                    setChangeTime={setChangeTime}
                    changeTime={changeTime}
                    changePlace={changePlace}
                    meetingPlace={meetingPlace}
                    nextStep={props.nextStep}
                    setLoading={setLoading}
                  />
                )}
              </div>

              {/* <OccasionsContainer /> */}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div style={{ margin: 90 }} />
      {!changeTime && !changePlace ? (
        <div className="buttonGroup">
          <Button
            name={props.progress === 0 ? "Cancel" : "Back"}
            buttonClick={
              props.progress === 0 ? props.toggleMeetingModal : props.prevStep
            }
            className="plain buttonPrev"
          />
          <Button
            name={props.progress === 0 ? "Confirm" : "Done"}
            buttonClick={async () => {
              switch (props.progress) {
                case 0:
                  setChanging(true);

                  return;
                case 1:
                  if (meetingTime != "") {
                    props.nextStep();
                  }
                  return;
              }
              // if (props.progress === 3) {

              // } else {
              //   props.nextStep();
              // }
            }}
            className="plain"
          />
        </div>
      ) : null}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	all: {},
};

export const placesSlice = createSlice({
	name: 'places',
	initialState,
	reducers: {},
	extraReducers: {
		/**
	 	 * Dispatched when the manually places are added
	 	 */
		AllPlaces: (state, action) => {
			return {
				...state,
				all: action.payload,
			};
		},
		ClearPlaces: (state, action) => {
			return {
				...state,
				all: [],
			};
		}
	}
});

export const places = state => state.places;
export default placesSlice.reducer;
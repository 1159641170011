import React, { useState, useEffect } from "react";

import InvitationDetails from "./InvitationDetails";
import Button from "../buttons/Button";
import { Scrollbars } from "react-custom-scrollbars";
import { store } from "../../store/store";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MeetUsAppIcon } from "../../assets/icons/meetus-app-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { JoinInvite } from "../../store/actions/actionConstants";

import Network from "../../store/services";
import { ThreeDots } from "react-loader-spinner";

export default function InvitationDetailsContainer(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  console.log("invitation that I have: ", props.invitation);
  const [loading, setLoading] = useState(false);
  const state = store.getState();
  const currentUser = state.currentUser;
  // ------------------------------------------------------------ needs work
  async function getMeetingDetails() {
    dispatch(
      Network.networkCall({
        action: JoinInvite,
        data: {
          meeting_id: "",
        },
      })
    )
      .then(function (result) {
        console.log("Got the meeting details", result);
      })
      .catch(function (error) {
        console.log("Failed to Update Meeting.", error);
      });
  }

  function isEmpty(obj) {
    if (obj === undefined || obj === null) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  async function joinMeeting() {
    // setShowLoader(true);
    setLoading(true);
    dispatch(
      Network.networkCall({
        action: JoinInvite,
        data: {
          // Meeting data from organizer - not to be send from other joiners
          meeting_title: props.invitation.meeting_title,
          meeting_time: props.invitation.meeting_time,
          meeting_description: props.invitation.meeting_description,
          agenda: props.invitation.agenda,
          meeting_place: props.invitation.meeting_place,
          meeting_type: props.invitation.meeting_type,
          creation_time: props.invitation.creation_time,
          meeting_end_date: props.invitation.meeting_end_date,
          // Data to join a meeting
          agenda: "",
          user_id: currentUser.userID,
          meeting_id: props.invitation.meeting_id,
          name: currentUser.nickName,
          user_profile_picture: currentUser.profilePic,
          gps: {
            // lat: userAddedGPS ? userGPS.lat : "",
            // lng: userAddedGPS ? userGPS.lng : "",
            lat: "",
            lng: "",
          },
          transport: "",
          is_recent: "",
          meetingTime: props.invitation.meeting_time,
          name: currentUser.nickName,
          user_arn: currentUser.userARN,
          joining_time: Date.now(),
          topic_arn: props.invitation.topic_arn,

          organizer_user_id: props.invitation.organizer_user_id,
          organizer_name: props.invitation.organizer_name,
          ...(!isEmpty(props.invitation.Slots) && {
            slots: props.invitation.Slots,
          }),
        },
      })
    )
      .then((response) => {
        console.log("Joined meeting.", response);
        store.dispatch({
          type: "UnArchieveMeeting",
          payload: props.invitation.meeting_id,
        });
        if (response !== undefined && response.success === true) {
          if (Object.values(props.invitation.meeting_place).length > 1) {
            props.setPlaceVoting(true);
          } else if (
            props.invitation.meeting_time === "" ||
            props.invitation.meeting_time === "invalid date"
          ) {
            props.setSlotVoting(true);
          } else {
            navigate("/meetings");
          }
        }
        setLoading(false);
        // 	// Why is this one here? Normally it should only only get back as object with success value
        // 	let noOfPlaces = Object.values(response.data.meeting_place).length;
        // 	response.data.meeting_time === '' ||
        // 	response.data.meeting_time === 'Invalid date'
        // 		? !dateVoteEnded
        // 			? response.data.date_user_voted
        // 				? response.data.user_voted
        // 					? navigation.navigate(SCREEN_MEETING, {
        // 							meeting_id: props.invitation.MeetingID,
        // 							name: props.invitation.MeetingTitle,
        // 					  })
        // 					: noOfPlaces > 1
        // 					? navigation.navigate(SCREEN_VOTING, {
        // 							meeting_id: props.invitation.MeetingID,
        // 							name: props.invitation.MeetingTitle,
        // 					  })
        // 					: navigation.navigate(SCREEN_MEETING, {
        // 							meeting_id: props.invitation.MeetingID,
        // 							name: props.invitation.MeetingTitle,
        // 					  })
        // 				: navigation.navigate(SCREEN_VOTING_DATES, {
        // 						meeting_id: props.invitation.MeetingID,
        // 						name: props.invitation.MeetingTitle,
        // 				  })
        // 			: response.data.user_voted
        // 			? navigation.navigate(SCREEN_MEETING, {
        // 					meeting_id: props.invitation.MeetingID,
        // 					name: props.invitation.MeetingTitle,
        // 			  })
        // 			: noOfPlaces > 1
        // 			? navigation.navigate(SCREEN_VOTING, {
        // 					meeting_id: props.invitation.MeetingID,
        // 					name: props.invitation.MeetingTitle,
        // 			  })
        // 			: noOfPlaces === 0
        // 			? !userAddedGPS
        // 				? navigation.navigate(SCREEN_CHOOSE_START_LOCATION, {
        // 						meeting_id: props.invitation.MeetingID,
        // 						name: props.invitation.MeetingTitle,
        // 				  })
        // 				: navigation.navigate(SCREEN_MEETING_MAP, {
        // 						meeting_id: props.invitation.MeetingID,
        // 						name: t('MAP_PROGRESS'),
        // 						data: {
        // 							lat: userGPS.lat,
        // 							lng: userGPS.lng,
        // 						},
        // 				  })
        // 			: navigation.navigate(SCREEN_MEETING, {
        // 					meeting_id: props.invitation.MeetingID,
        // 					name: props.invitation.MeetingTitle,
        // 			  })
        // 		: response.data.user_voted
        // 		? navigation.navigate(SCREEN_MEETING, {
        // 				meeting_id: props.invitation.MeetingID,
        // 				name: props.invitation.MeetingTitle,
        // 		  })
        // 		: noOfPlaces > 1
        // 		? navigation.navigate(SCREEN_VOTING, {
        // 				meeting_id: props.invitation.MeetingID,
        // 				name: props.invitation.MeetingTitle,
        // 		  })
        // 		: noOfPlaces === 0
        // 		? !userAddedGPS
        // 			? navigation.navigate(SCREEN_CHOOSE_START_LOCATION, {
        // 					meeting_id: props.invitation.MeetingID,
        // 					name: props.invitation.MeetingTitle,
        // 			  })
        // 			: navigation.navigate(SCREEN_MEETING_MAP, {
        // 					meeting_id: props.invitation.MeetingID,
        // 					name: t('MAP_PROGRESS'),
        // 					data: {
        // 						lat: userGPS.lat,
        // 						lng: userGPS.lng,
        // 					},
        // 			  })
        // 		: navigation.navigate(SCREEN_MEETING, {
        // 				meeting_id: props.invitation.MeetingID,
        // 				name: props.invitation.MeetingTitle,
        // 		  });
        // } else {
        // 	throw new Error('Could not join the meeting.');
        // }
        // return response;
      })
      .catch(async (err) => {
        console.log("Problem joining the meeting", err);
        setLoading(false);
      });
  }
  return (
    <Scrollbars thumbSize={200}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ED3424"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={props.invitation ? false : true}
      />
      {props.invitation ? (
        <div>
          <InvitationDetails invitation={props.invitation} />
          <div className="btn_vote">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#ED3424"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
            {loading ? null : (
              <Button
                name={"Join Meeting"}
                icon={<MeetUsAppIcon className="button__icon" />}
                buttonClick={() => joinMeeting()}
              />
            )}
          </div>
        </div>
      ) : null}
    </Scrollbars>
  );
}

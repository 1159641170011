import Navbar from '../components/navbar/Navbar';

export default function Dashboard() {
  return (
    <div className="app__content--grid dashboard">
      <Navbar />
      Dashboard
    </div>
  );
}

export const Paths = {
  // pages
  Dashboard: { path: "/" },
  Meetings: { path: "/meetings" },
  MeetingInvitation: { path: "/invitation/:meetingID" },
  Login: { path: "/auth/login" },
  SignUp: { path: "/auth/signup" },
  // docs

  // components
};

import React, { useState, useEffect } from "react";
import { UpdateMeeting } from "../../store/actions/actionConstants";
import Details from "./Details";
import Button from "../buttons/Button";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../store/services";
import Network from "../../store/services";
import { ThreeDots } from "react-loader-spinner";

import { ReactComponent as MeetUsAppIcon } from "../../assets/icons/meetus-app-icon.svg";
import createShortLink from "../../logic/api/firebaseCreateLink";

export default function DetailsContainer(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.detailedMeeting != "" && props.detailedMeeting != undefined) {
      // make a call and update this component's state
      getMeetingDetails();
    }
  }, [props.detailedMeeting]);

  async function getMeetingDetails() {
    setLoading(true);
    dispatch(
      Network.networkCall({
        action: UpdateMeeting,
        data: {
          meeting_id: props.detailedMeeting,
        },
      })
    )
      .then(function (result) {
        console.log("Got the meeting details", result);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to Update Meeting.", error);
      });
  }

  return (
    <Scrollbars thumbSize={200}>
      <div>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#ED3424"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={loading}
        />
        {loading ? null : (
          <Details
            toggleChangeMeetingModal={props.toggleChangeMeetingModal}
            detailedMeeting={props.detailedMeeting}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </Scrollbars>
  );
}

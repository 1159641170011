import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useClickOutsideRef } from '../../logic/hooks/useClickOutsideRef';
import DropdownToggle from './DropdownToggle';
import Dropdown from './Dropdown';

import './dropdown.scss';

export default function DropdownContainer(props) {
  const [isOpen, setIsOpen] = useState(false);

  // handling clicks outside of dropdown
  const wrapperRef = useRef(null);
  useClickOutsideRef(wrapperRef, setIsOpen);

  // animation and height calculation
  const calcHeight = 80 + props.dropdownItems.length * props.heightFactor;
  const calcDelay = 0.1 + props.dropdownItems.length * 0.0075;
  const variants = {
    open: {
      height: calcHeight,
      transition: {
        type: 'spring',
        bounce: 0,
      },
    },
    closed: {
      height: 50,
      transition: {
        type: 'spring',
        delay: calcDelay,
        bounce: 0,
      },
    },
  };

  const toggleChecked = () => setIsOpen((value) => !value);

  return (
    <div
      className="dropdown__container"
      style={{ width: `${props.width}` }}
      ref={wrapperRef}
    >
      <motion.div
        className="dropdown"
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
      >
        <div className="dropdown__button" onClick={() => toggleChecked()}>
          {props.icon}
          {props.activeItem}
          <DropdownToggle />
        </div>
        <Dropdown
          dropdownItems={props.dropdownItems}
          activeItem={props.activeItem}
          onClick={props.onClick}
          cycleIsOpen={setIsOpen}
        />
      </motion.div>
    </div>
  );
}

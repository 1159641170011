import React, { useState, useEffect } from "react";

import PlaceVote from "./PlaceVote";
import Button from "../buttons/Button";
import { Scrollbars } from "react-custom-scrollbars";
import { store } from "../../store/store";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MeetUsAppIcon } from "../../assets/icons/meetus-app-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Vote } from "../../store/actions/actionConstants";

import Network from "../../store/services";
import { ThreeDots } from "react-loader-spinner";

export default function PlaceVoteContainer(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  console.log("invitation that I have: ", props.invitation);
  const [loading, setLoading] = useState(false);
  const state = store.getState();
  const currentUser = state.currentUser;
  const [voting, setVoting] = useState({});
  // ------------------------------------------------------------ needs work

  const votes = {
    topic_arn: props.invitation?.topic_arn,
    user_id: currentUser.userID,
    votes: {},
    meeting_id: props.invitation?.meeting_id,
    name: currentUser.nickName,
    title: props.invitation?.meeting_title,
  };

  console.log("votes: ", votes.votes);
  async function addVote(placeID) {
    if (votes.votes[placeID] === undefined || votes.votes[placeID] === 0) {
      votes.votes[placeID] = 1;
    } else {
      votes.votes[placeID] = 0;
    }
    console.log("votes: ", votes);
  }

  async function vote() {
    setLoading(true);

    let voted = false;
    try {
      Object.entries(votes.votes).map(([key, value]) => {
        if (value === 1) {
          voted = true;
        }
      });
    } catch (error) {
      console.log(error);
    }

    if (voted) {
      dispatch(
        Network.networkCall({
          action: Vote,
          data: votes,
        })
      )
        .then(function (result) {
          console.log("Successfully registered the voting", result);
          if (result.success === true) {
            setLoading(false);
            props.setPlaceVoting(false);
            if (!props.slotVoting) {
              navigate("/meetings");
            }
            return "success";
          } else {
            throw new Error("Could not complete the vote.");
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log("Failed to End voting.", error);
        });
    } else {
      setLoading(false);
    }
  }

  return (
    <Scrollbars thumbSize={200}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ED3424"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={props.invitation ? false : true}
      />
      {props.invitation ? (
        <div>
          <PlaceVote
            meetingPlaces={props.invitation.meeting_place}
            meeting={props.invitation}
            addVote={addVote}
            loading={loading}
          />
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#ED3424"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />
          {loading ? null : <Button buttonClick={() => vote()} name={"Vote"} />}
        </div>
      ) : null}
    </Scrollbars>
  );
}

import React from 'react';
import TabNavItem from './TabNavItem';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Calendar } from './calendar.svg';

import './tabnav.scss';

export default function TabNav() {
  const NavItems = [
    {
      name: 'Calendar',
      to: '/calendar',
      icon: <Calendar className="tabNav__icon" />,
    },
    {
      name: 'Meetings',
      to: '/meetings',
      icon: <Filter className="tabNav__icon" />,
    },
    {
      name: 'Filter',
      to: '/filter',
      icon: <Filter className="tabNav__icon" />,
    },
  ];

  return (
    <div className="tabNav__bar">
      <div className="tabNav__container">
        {NavItems.map((item) => {
          return (
            <TabNavItem
              key={item.name}
              name={item.name}
              to={item.to}
              icon={item.icon}
            />
          );
        })}
      </div>
    </div>
  );
}

import _ from 'lodash';
import * as AllApis from './apiConstants';
import Network from './network';

const Success = 'Success';
const Failure = 'Failure';

const networkCall = data => dispatch => {
	let action = data.action;
	let apiObject = AllApis[action];

	if (!apiObject) {
		return new Promise((resolve, reject) => {
			resolve({
				success: false,
				data: 'Action not found',
			});
		});
	}

	return Network(apiObject, data.data)
		.then(resp => {
			if (resp.status && resp.status === 200) {
				dispatch({type: action + Success, payload: resp.data});
				return {
					success: true,
					data: resp.data,
				};
			} else {
				return {
					success: false,
					data: resp,
				};
			}
		})
		.catch(err => {
			console.log('error2', err);
		});
};

const reducerCall = data => async dispatch => {
	dispatch({type: data.action, payload: data.data});
};

export default {
	networkCall,
	reducerCall,
};

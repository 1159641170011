import React, { useState, useEffect, useRef } from "react";

import "./picker.scss";
import Picker from "./Picker";

const hours = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const minutes = ["00", "10", "20", "30", "40", "50"];

export default function TimePicker(props) {
  const [minutesIsOpen, setMinutesIsOpen] = useState(false);
  const toggleMinutesIsOpen = () => setMinutesIsOpen((value) => !value);
  const [minute, setMinute] = useState("00");
  function setActiveMinutesOnClick(minute) {
    setMinute(minute);
    props.setTime(hour + ":" + minute);
  }
  const [hoursIsOpen, setHoursIsOpen] = useState(false);
  const toggleHoursIsOpen = () => setHoursIsOpen((value) => !value);
  const [hour, setHour] = useState("12");
  function setActiveHoursOnClick(hour) {
    setHour(hour);
    props.setTime(hour + ":" + minute);
  }

  return (
    <>
      <Picker
        content={hours}
        variable={hour}
        isOpen={hoursIsOpen}
        toggleIsOpen={toggleHoursIsOpen}
        onClick={setActiveHoursOnClick}
        buttonWidth={"40px"}
        overlayWidth={"90px"}
      />
      <span>:</span>
      <Picker
        content={minutes}
        variable={minute}
        isOpen={minutesIsOpen}
        toggleIsOpen={toggleMinutesIsOpen}
        onClick={setActiveMinutesOnClick}
        buttonWidth={"40px"}
        overlayWidth={"90px"}
      />
    </>
  );
}

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { GetInvitation } from "../store/actions/actionConstants";
import Network from "../store/services";

import Navbar from "../components/navbar/Navbar";
import CardContainer from "../components/cards/CardContainer";
import InvitationDetailsContainer from "../components/invitation/InvitationDetailsContainer";
import PlaceVoteContainer from "../components/placeVote/PlaceVoteContainer";

import { Mobile, Tablet, Desktop } from "../styles/Responsive";
import TabNav from "../components/tabnav/TabNav";
import MobileMenu from "../components/MobileMenu/MobileMenu";

import { useParams } from "react-router-dom";
export default function MeetingInvitation(props) {
  const [invitationDetails, setInvitationDetails] = useState("");
  const [placeVoting, setPlaceVoting] = useState(false);
  const [slotVoting, setSlotVoting] = useState(false);
  const dispatch = useDispatch();
  let { meetingID } = useParams();
  // const meetingID = props.match.params.meetingID;
  console.log("I am getting the meetin ID: ", meetingID);
  useEffect(() => {
    if (meetingID != "" && meetingID != undefined) {
      // make a call and update this component's state
      getMeetingDetails();
    }
  }, [meetingID]);

  async function getMeetingDetails() {
    dispatch(
      Network.networkCall({
        action: GetInvitation,
        data: {
          meeting_id: meetingID,
        },
      })
    )
      .then(function (result) {
        setInvitationDetails(result.data.Items[0]);
        console.log("Got the meeting details", result);
      })
      .catch(function (error) {
        console.log("Failed to Update Meeting.", error);
      });
  }

  return (
    <>
      <Mobile>
        <div className="m-container">
          <MobileMenu />
          <div className="m-content__grid meetings">
            <div className="m-card__container">
              <CardContainer />
            </div>
            <div className="m-details__container">
              <InvitationDetailsContainer
                invitation={invitationDetails}
                setPlaceVoting={setPlaceVoting}
              />
            </div>
          </div>
          <TabNav />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-container">
          <MobileMenu />
          <div className="t-content__grid meetings">
            <div className="t-details__container">
              <InvitationDetailsContainer
                invitation={invitationDetails}
                setPlaceVoting={setPlaceVoting}
              />
            </div>
          </div>
          <TabNav />
        </div>
      </Tablet>
      <Desktop>
        <div className="d-container">
          <div className="d-content__grid meetings">
            <Navbar />

            {/* <div className="d-details__container"> */}
            <InvitationDetailsContainer
              invitation={invitationDetails}
              setPlaceVoting={setPlaceVoting}
              setSlotVoting={setPlaceVoting}
            />
            {placeVoting ? (
              <PlaceVoteContainer
                invitation={invitationDetails}
                setPlaceVoting={setPlaceVoting}
                slotVoting={slotVoting}
              />
            ) : slotVoting ? (
              <PlaceVoteContainer
                invitation={invitationDetails}
                setSlotVoting={setSlotVoting}
              />
            ) : null}

            {/* </div> */}
          </div>
        </div>
      </Desktop>
    </>
  );
}

import React, { useEffect } from "react";
export default function Day(props) {
  useEffect(() => {
    props.setShowMeeting(meetings);
  }, [props.date]);
  let meetings = Object.values(props.date.meeting);
  return (
    <div
      date={props.date}
      // disabled={disabled}
      className={props.className}
      onClick={() => {
        props.setActiveDayOnClick(props.dayOfMonth);
        props.setShowMeeting(meetings);
        // props.setDetailedMeeting("");
      }}
    >
      <div className="day__meta">
        <h4>{props.dayOfWeek}</h4>
        <h3>{props.dayOfMonth}</h3>
      </div>
      {meetings.length > 0 ? (
        <div className="day__meeting__count">
          <h4>
            {meetings.length > 1
              ? meetings.length + " - Meetings"
              : meetings[0]?.MeetingTitle}
          </h4>
        </div>
      ) : (
        <div className="day__meeting__no_meeting">
          <h4>No Meetings</h4>
        </div>
      )}
    </div>
  );
}

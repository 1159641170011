import React, { useEffect } from "react";
import { store } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import Network from "../../store/services";
import {
  LeaveMeeting,
  DeleteMeeting,
} from "../../store/actions/actionConstants";
import moment from "moment";
import "./details.scss";
import tmpicon from "../../assets/img/icon.JPG";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
} from "../../assets/images";

import ShareMeeting from "../shareMeeting/ShareMeeting";
import Button from "../buttons/Button";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import Participants from "../participants/Participants";

const details_data = {
  icon_img: tmpicon,
  meeting_details: "Meeting Details",
  meeting_name: "Meeting Name",
  participant: "Participant",
  participants: "Participants",
  date: "13.01.2021",
  time: "19:30",
  leave: "Leave",
  restaurant: "Restaurant",
  meeting_text:
    "Description Text, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  aliqua.",
};

export default function Details(props) {
  const dispatch = useDispatch();
  const state = store.getState();
  const currentUser = state.currentUser;
  const detailedMeetings = state.meetings.meetings[props.detailedMeeting];
  const places = detailedMeetings?.MeetingPlace
    ? Object.values(detailedMeetings?.MeetingPlace)
    : [];
  const meetingParticipants = detailedMeetings?.Participants
    ? Object.values(detailedMeetings?.Participants)
    : [];
  // console.log("detatiledMeeting: ", detailedMeetings, props.detailedMeeting);

  async function leaveMeeting() {
    props.setLoading(true);
    dispatch(
      Network.networkCall({
        action: LeaveMeeting,
        data: {
          user_id: currentUser.userID,
          meeting_id: detailedMeetings?.MeetingID,
        },
      })
    )
      .then((result) => {
        if (result.success === true) {
          console.log("Successfully left the meeting", result);
        }
        props.setLoading(false);
      })
      .catch((error) => {
        console.log("Failed to leave meeting.", error);
        props.setLoading(false);
      });
  }

  async function deleteMeeting() {
    props.setLoading(true);
    return dispatch(
      Network.networkCall({
        action: DeleteMeeting,
        data: {
          meeting_id: detailedMeetings?.MeetingID,
          user_id: currentUser.userID,
          sub_arn: detailedMeetings?.SubscriptionArn,
          meeting_title: detailedMeetings?.MeetingTitle,
          topic_arn: detailedMeetings?.TopicArn,
          user_name: currentUser.nickName,
        },
      })
    )
      .then(function (result) {
        if (result.success === true) {
          console.log("Successfully deleted the meeting", result);
        }
        props.setLoading(false);
      })
      .catch(function (error) {
        props.setLoading(false);
        console.log("Failed to delete meeting.", error);
      });
  }

  return (
    <>
      {props.detailedMeeting != "" &&
      props.detailedMeeting != undefined &&
      props.detailedMeeting.archieved != true ? (
        <div className="d-details__container">
          <span className="meeting__details">
            {details_data.meeting_details}
          </span>
          <span className="meeting__name">
            {detailedMeetings?.MeetingTitle}
          </span>
          {/* <div>
            <img className="icontmp" src={user} alt="icon" />
            <span className="participant__text">
              {detailedMeetings?.UserName}
            </span>
          </div> */}
          <div className="restaurant__info">
            <div className="restaurant__data">
              <img className="icontmp" src={place} alt="icon" />
              <span className="restaurant__text">
                {places.length === 1 && places[0].place_type != "cardMap"
                  ? places[0].place_title
                  : places.length === 1 && places[0].place_type === "cardMap"
                  ? "Place Address"
                  : "na"}
              </span>
            </div>
            <div className="restaurant__data">
              <img className="icontmp" src={calendar} alt="icon" />
              <span className="restaurant__text">
                {moment(new Date(detailedMeetings?.MeetingTime)).format(
                  "DD.MM.YYYY"
                )}
              </span>
            </div>
            <div className="restaurant__data1">
              <img className="icontmp" src={time} alt="icon" />
              <span className="restaurant__text">
                {moment(new Date(detailedMeetings?.MeetingTime)).format(
                  "HH:mm"
                )}
              </span>
            </div>
          </div>
          <span className="meeting__text">
            {detailedMeetings?.MeetingDescription
              ? detailedMeetings?.MeetingDescription
              : "no discrition"}
          </span>
          <div className="participants_headline">
            <img className="icontmp" src={participants} alt="icon" />
            <span className="participants">{details_data.participants}</span>
          </div>
          <div className="pariticipants__details">
            <Participants
              participants={Object.values(detailedMeetings?.Participants)}
            />
          </div>

          <div className="btn_vote">
            <add-to-calendar-button
              name={detailedMeetings?.MeetingTitle}
              options="'Apple','Google'"
              location="World Wide Web"
              startDate={moment(new Date(detailedMeetings?.MeetingTime)).format(
                "YYYY-MM-DD"
              )}
              endDate={moment(new Date(detailedMeetings?.MeetingTime)).format(
                "YYYY-MM-DD"
              )}
              startTime={moment(new Date(detailedMeetings?.MeetingTime)).format(
                "HH:mm"
              )}
              endTime="23:00"
              timeZone="America/Los_Angeles"
            ></add-to-calendar-button>
            <AddToCalendarButton
              name={detailedMeetings?.MeetingTitle}
              startDate={detailedMeetings?.MeetingTime}
              options={["Apple", "Google", "Yahoo", "iCal"]}
              size="8|8|8"
            ></AddToCalendarButton>
            <ShareMeeting
              meetingID={detailedMeetings?.MeetingID}
              meetingTitle={detailedMeetings?.MeetingTitle}
            />
            {currentUser.userID === detailedMeetings?.OrganizerID ? (
              <>
                <Button
                  name={"Cancel Meeting"}
                  icon={<img className="icontmp" src={cancel} alt="icon" />}
                  buttonClick={() => deleteMeeting()}
                />
                <Button
                  name={"Change Meeting"}
                  icon={<img className="icontmp" src={change} alt="icon" />}
                  buttonClick={() => props.toggleChangeMeetingModal()}
                />
              </>
            ) : (
              <Button
                name={"Leave Meeting"}
                icon={<img className="icontmp" src={cancel} alt="icon" />}
                buttonClick={() => leaveMeeting()}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <span className="meeting__details">
            {details_data.meeting_details +
              "\n\n\n" +
              "Please select a meeting.\n"}
          </span>
        </>
      )}
    </>
  );
}

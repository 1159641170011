import NavItem from "./NavItem";

import { ReactComponent as LogoLight } from "../../assets/logo/logo-light.svg";
import NavbarProfile from "../userprofile/NavbarProfile";
import { useNavigate } from "react-router-dom";

import { purgeData } from "../../store/store";

const NavItems = [
  {
    name: "Dashboard",
    to: "/",
    //   icon: <TypographyMenu className={'navSidebarItemIcon'} />,
  },
  {
    name: "Meetings",
    to: "/meetings",
    //   icon: <SupportMenu className={'navSidebarItemIcon'} />,
  },
  {
    name: "Profile",
    to: "/profile",
    // icon: <Calendar className="logo"/>,
  },
];

export default function Navbar() {
  let navigate = useNavigate();
  return (
    <div>
      <LogoLight className="logo--light" />
      <NavbarProfile />
      <div className="navbar">
        {NavItems.map((item) => {
          return (
            <NavItem
              key={item.name}
              name={item.name}
              to={item.to}
              // icon={item.icon}
            />
          );
        })}
      </div>
      <div
        onClick={async () => {
          await purgeData();
          navigate("/");
        }}
        className="navItem"
      >
        Logout
      </div>
    </div>
  );
}

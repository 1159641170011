import { useEffect } from 'react';

// hook that handles clicks outside of the passed ref
export function useClickOutsideRef(ref, toggleState) {
  useEffect(() => {
    // run if clicked on outside of element
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleState(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

import { store } from "../../store/store";
import moment from "moment";
import "./invitationDetails.scss";
import tmpicon from "../../assets/img/icon.JPG";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
} from "../../assets/images";

const details_data = {
  icon_img: tmpicon,
  meeting_details: "Meeting Details",
  meeting_name: "Meeting Name",
  participant: "Participant",
  participants: "Participants",
  date: "13.01.2021",
  time: "19:30",
  leave: "Leave",
  restaurant: "Restaurant",
  meeting_text:
    "Description Text, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  aliqua.",
};

export default function InvitationDetails(props) {
  const places = props.invitation.meeting_place
    ? Object.values(props.invitation?.meeting_place)
    : [];

  return (
    <>
      <div className="d-details__container">
        <span className="meeting__details">{details_data.meeting_details}</span>
        <span className="meeting__name">{props.invitation?.meeting_title}</span>
        <span className="restaurant__data">{"Invited by:"}</span>
        <div>
          <img className="icontmp" src={user} alt="icon" />
          <span className="participant__text">
            {props.invitation?.organizer_name}
          </span>
        </div>

        <div className="restaurant__info">
          <span className="restaurant__data">{"Where:"}</span>
          <div className="restaurant__data">
            <img className="icontmp" src={place} alt="icon" />
            <span className="restaurant__text">
              {places.length === 0
                ? "Midpoint Meeting - You give your startpoint and we calculate the midpoint between all of you."
                : places.length === 1 && places[0].place_type != "cardMap"
                ? places[0].place_title
                : places.length === 1 && places[0].place_type === "cardMap"
                ? "Place Address"
                : places.length > 1
                ? "Ongoing Place Voting"
                : "na"}
            </span>
          </div>

          <span className="restaurant__data">{"When:"}</span>
          {props.invitation?.meeting_time ? (
            <div>
              <div className="restaurant__data">
                <img className="icontmp" src={calendar} alt="icon" />
                <span className="restaurant__text">
                  {moment(new Date(props.invitation?.meeting_time)).format(
                    "DD.MM.YYYY"
                  )}
                </span>
              </div>
              <div className="restaurant__data1">
                <img className="icontmp" src={time} alt="icon" />
                <span className="restaurant__text">
                  {moment(new Date(props.invitation?.meeting_time)).format(
                    "HH:mm"
                  )}
                </span>
              </div>
            </div>
          ) : (
            <span>{"Participants are voting for date options."}</span>
          )}
          <p className="restaurant__data">{"Description:"}</p>
          <span className="meeting__text">
            {props.invitation?.meeting_description
              ? props.invitation?.meeting_description
              : "no discrition"}
          </span>

          <div className="pariticipants__details"></div>
        </div>
      </div>
    </>
  );
}

import { useState } from 'react';
import { motion } from 'framer-motion';

import './progressMeter.scss';

export default function ProgressMeter(props) {
  const currentStatusTransition = {
    duration: 1,
    type: 'tween',
  };
  return (
    <>
      <div className="meterContainer">
        <div className="statusBar" style={{ width: props.width + '%' }}>
          <motion.div
            animate={{
              width: props.progress * (100 / (props.steps.length - 1)) + '%',
            }}
            transition={currentStatusTransition}
            className="currentStatus"
          ></motion.div>
        </div>
        <ul className="stepsContainer">
          {props.steps.map((item, index) => {
            return (
              <li
                key={item.id}
                className="stepsItem"
                style={{ width: 100 / props.steps.length + '%' }}
              >
                <motion.div
                  animate={{
                    scale:
                      props.progress === index ? [1, 1.05, 1.1, 1.05, 1] : 1,
                    border:
                      props.progress + 1 <= index
                        ? '2px solid rgba(190, 190, 190, 1)'
                        : '2px solid rgba(32, 241, 14, 1)',
                    backgroundColor:
                      props.progress <= index
                        ? 'rgba(255, 255, 255, 1)'
                        : 'rgba(32, 241, 14, 1)',
                  }}
                  className="progressStep"
                ></motion.div>
                <motion.h4
                  className="stepName"
                  animate={{
                    scale:
                      props.progress === index ? [1, 1.05, 1.1, 1.05, 1] : 1,
                    color:
                      props.progress + 1 <= index
                        ? 'rgba(190, 190, 190, 1)'
                        : 'rgba(32, 241, 14, 1)',
                  }}
                >
                  {item.name}
                </motion.h4>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

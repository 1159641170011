// import config from 'react-native-config';

// Login
const get = "GET";
const post = "POST";
const put = "PUT";
const deleteMethod = "DELETE";
const stage = process.env.REACT_APP_STAGE;

export const GlobalAuth = {
  api: process.env.REACT_APP_authAPI,
  // api: '/dev-meetus-auth/auth',
  method: get,
  noSigning: true,
};

export const CreateUser = {
  api: process.env.REACT_APP_createUserAPI,
  // api: '/dev-meetus-users-sync/create-user-sync',
  method: post,
};

export const CreateNewMeeting = {
  api: process.env.REACT_APP_createMeetingAPI,
  // api: '/dev-meetus-meetings-sync/create-meeting-sync',
  method: post,
};

export const JoinMyMeeting = {
  api: process.env.REACT_APP_joinMeetingAPI,
  // api: '/dev-meetus-join-meetings/join-meeting-sqs',
  method: post,
};

export const JoinInvite = {
  api: process.env.REACT_APP_joinMeetingAPI,
  // api: '/dev-meetus-join-meetings/join-meeting-sqs',
  method: post,
};

export const GetMeetingStatus = {
  api: process.env.REACT_APP_meetingStatusAPI,
  // api: '/dev-meetus-status/meetingstatus?creation_time&meeting_id',
  method: get,
};

// we are getting the places after matrix request which is why this API is called that way
export const GetMeetingPlaces = {
  api: process.env.REACT_APP_meetingPlacesAPI,
  // api: '/dev-meetus-distance-matrix/get-distances?meeting_id&creation_time',
  method: get,
};

// this is the current state for search places and starts with the center calculation
export const CalculateCenterSearchPlaces = {
  api: process.env.REACT_APP_centerProcessAPI,
  // api: '/dev-meetus-center-process/center-process',
  metod: post,
};

// this one can be used in different ways depending on input
//      -> list all meetings of user with user_id input
//      -> list all participants of Meeting with meeting_id
export const GetUserData = {
  api: process.env.REACT_APP_getUserDataAPI,
  // api: '/dev-meetus-meeting-data/meeting-data',
  method: post,
};
// created the same API here again to differentiate in the reducers
export const GetInvitation = {
  api: process.env.REACT_APP_getMeetingInfoAPI,
  method: post,
};

// created the same API here again to differentiate in the reducers
export const GetUserMeetings = {
  api: process.env.REACT_APP_getUserMeetingsAPI,
  method: post,
};

// created the same API here again to differentiate in the reducers
export const ListMeetings = {
  api: process.env.REACT_APP_getUserMeetingsAPI,
  method: post,
};

// both for voting and ending the vote
export const Vote = {
  api: process.env.REACT_APP_voteAPI,
  method: post,
};
export const EndVote = {
  api: process.env.REACT_APP_voteAPI,
  method: post,
};
// to check the current status of the voting
export const GetVotes = {
  api: process.env.REACT_APP_getVotesAPI,
  method: post,
};

export const UpdateMeeting = {
  api: process.env.REACT_APP_getMeetingInfoAPI,
  method: post,
};

export const CleanSubscriptions = {
  api: process.env.REACT_APP_cleanSubscriptionsAPI,
  method: post,
};

export const Resubscribe = {
  api: process.env.REACT_APP_resubscribeAPI,
  method: post,
};

export const DeleteAccount = {
  api: process.env.REACT_APP_deleteAccountAPI,
  method: post,
};

export const CheckAppVersion = {
  api: process.env.REACT_APP_checkAppVersionAPI,
  method: get,
  noSigning: true,
};

export const FindPlaces = {
  api: process.env.REACT_APP_findPlacesAPI,
  method: post,
};

export const UploadProfilePic = {
  api: process.env.REACT_APP_uploadProfilePicAPI,
  method: post,
};

export const UpdateMeetingPlaces = {
  api: process.env.REACT_APP_updateMeetingPlacesAPI,
  method: post,
};

export const LeaveMeeting = {
  api: process.env.REACT_APP_leaveMeetingAPI,
  method: post,
};

export const DeleteMeeting = {
  api: process.env.REACT_APP_deleteMeetingAPI,
  method: post,
};

export const UpdateMeetingDetails = {
  api: process.env.REACT_APP_updateMeetingDetailsAPI,
  method: post,
};

export const UpdateMeetingSlots = {
  api: process.env.REACT_APP_updateMeetingSlotsAPI,
  method: post,
};

// both for voting and ending the vote for date options
export const DateVote = {
  api: process.env.REACT_APP_dateVoteAPI,
  method: post,
};

export const EndDateVote = {
  api: process.env.REACT_APP_dateVoteAPI,
  method: post,
};

// to check the current status of the date voting
export const GetDateVotes = {
  api: process.env.REACT_APP_getDateVotesAPI,
  method: post,
};

export const UpdateGps = {
  api: process.env.REACT_APP_updateGpsAPI,
  method: post,
};

import { Mobile, Tablet, Desktop } from "../styles/Responsive";

import ImgSlider from "../components/imgSlider/ImgSlider";

import "./login.scss";
import SignUpForm from "../components/forms/SignUpForm";

export default function Login(props) {
  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <SignUpForm getToken={props.getToken} />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <SignUpForm getToken={props.getToken} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <SignUpForm getToken={props.getToken} />
          <ImgSlider />
        </div>
      </Desktop>
    </>
  );
}

// leave off @2x/@3x
import calendar from "./icons/calendar.svg";
import time from "./icons/time.svg";
import participants from "./icons/participants.svg";
import place from "./icons/place.svg";
import user from "./icons/user.svg";
import invite from "./icons/invite.svg";
import confirm from "./icons/confirm.svg";
import cancel from "./icons/cancel.svg";
import chat from "./icons/chat.svg";
import change from "./icons/change.svg";
import exit from "./icons/meetus-exit-icon.svg";
import add from "./icons/add.svg";

export {
  calendar,
  time,
  participants,
  place,
  user,
  invite,
  cancel,
  confirm,
  chat,
  change,
  exit,
  add,
};
const images = {
  // avatars
  // avatars should stay that way since we are using numbers on mobile
  // There hold just the final
  placeholder_avatars: {
    86: require("./avatars/octopus_1a.png"),
    87: require("./avatars/octopus_1b.png"),
    88: require("./avatars/octopus_2a.png"),
    89: require("./avatars/octopus_2b.png"),
    90: require("./avatars/octopus_3a.png"),
    91: require("./avatars/octopus_3b.png"),
    92: require("./avatars/octopus_4a.png"),
    93: require("./avatars/octopus_4b.png"),
    94: require("./avatars/octopus_5a.png"),
    95: require("./avatars/octopus_5b.png"),
    96: require("./avatars/penguin_1a.png"),
    97: require("./avatars/penguin_1b.png"),
    98: require("./avatars/penguin_2a.png"),
    99: require("./avatars/penguin_2b.png"),
    100: require("./avatars/penguin_3a.png"),
    101: require("./avatars/penguin_3b.png"),
    102: require("./avatars/penguin_4a.png"),
    103: require("./avatars/penguin_4b.png"),
    104: require("./avatars/penguin_5a.png"),
    105: require("./avatars/penguin_5b.png"),
    106: require("./avatars/hawk_1a.png"),
    107: require("./avatars/hawk_1b.png"),
    108: require("./avatars/hawk_2a.png"),
    109: require("./avatars/hawk_2b.png"),
    110: require("./avatars/hawk_3a.png"),
    111: require("./avatars/hawk_3b.png"),
    112: require("./avatars/hawk_4a.png"),
    113: require("./avatars/hawk_4b.png"),
    114: require("./avatars/hawk_5a.png"),
    115: require("./avatars/hawk_5b.png"),
    116: require("./avatars/turtle_1a.png"),
    117: require("./avatars/turtle_1b.png"),
    118: require("./avatars/turtle_2a.png"),
    119: require("./avatars/turtle_2b.png"),
    120: require("./avatars/turtle_3a.png"),
    121: require("./avatars/turtle_3b.png"),
    122: require("./avatars/turtle_4a.png"),
    123: require("./avatars/turtle_4b.png"),
    124: require("./avatars/turtle_5a.png"),
    125: require("./avatars/turtle_5b.png"),
    126: require("./avatars/ghost_1a.png"),
    127: require("./avatars/ghost_1b.png"),
    128: require("./avatars/ghost_2a.png"),
    129: require("./avatars/ghost_2b.png"),
    130: require("./avatars/ghost_3a.png"),
    131: require("./avatars/ghost_3b.png"),
    132: require("./avatars/ghost_4a.png"),
    133: require("./avatars/ghost_4b.png"),
    134: require("./avatars/ghost_5a.png"),
    135: require("./avatars/ghost_5b.png"),
  },
};

export default images;

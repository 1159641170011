import { motion } from 'framer-motion';

const Path = (props) => {
  return (
    <motion.path
      fill="transparent"
      strokeWidth="1.5"
      stroke="hsl(0, 0%, 18%)"
      strokeLinecap="round"
      {...props}
    />
  );
};

export default function DropdownToggle() {
  return (
    <div className="dropdown__toggle">
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: 'M 12 12 L 20 4' },
            open: { d: 'M 12 4 L 20 12' },
          }}
        />
        <Path
          variants={{
            closed: { d: 'M 4 4 L 12 12' },
            open: { d: 'M 4 12 L 12 4' },
          }}
        />
      </svg>
    </div>
  );
}

import { store } from "../../store/store";
import moment from "moment";
// import "./details.scss";
import tmpicon from "../../assets/img/icon.JPG";
import {
  calendar,
  time,
  participants,
  place,
  user,
  cancel,
  change,
} from "../../assets/images";

export default function MeetingDetails(props) {
  const state = store.getState();
  const currentUser = state.currentUser;

  const places = Object.values(props.meeting.meeting_place);
  console.log("the meeting on the details confirm screen:  ", props);
  console.log("places:  ", places[0]?.place_type);

  return (
    <>
      <div className="d-details__container">
        <span className="meeting__details">{"Meeting Details"}</span>
        <span className="meeting__name">{props.meeting?.meeting_title}</span>
        <div>
          <img className="icontmp" src={user} alt="icon" />

          <span className="participant__text">{props.meeting?.name}</span>
        </div>
        <div>
          <span className="restaurant__text">
            {props.meeting?.meeting_description}
          </span>
        </div>
        <div className="restaurant__info">
          <div className="restaurant__data">
            <img className="icontmp" src={place} alt="icon" />
            <span className="restaurant__text">
              {places?.length >= 1 && places[0]?.place_type != "cardMap"
                ? places[0].place_title
                : places?.length === 1 && places[0]?.place_type === "cardMap"
                ? "Place Address"
                : "na"}
            </span>
          </div>
          <div className="restaurant__data">
            <img className="icontmp" src={calendar} alt="icon" />
            <span className="restaurant__text">
              {moment(new Date(props.meeting?.meeting_time)).format(
                "DD.MM.YYYY"
              )}
            </span>
          </div>
          <div className="restaurant__data1">
            <img className="icontmp" src={time} alt="icon" />
            <span className="restaurant__text">
              {moment(new Date(props.meeting?.meeting_time)).format("HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function confirmSignUp(verificationCode, username) {
  var userData = {
    Username: username,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) =>
    cognitoUser.confirmRegistration(
      verificationCode,
      true,
      function (err, result) {
        if (err) {
          reject(err);
          return;
        }
        // alert(
        // 	'You successfully registered for MeetUs and we automatically logged you in.',
        // );

        resolve(result);
      }
    )
  );
}

export default confirmSignUp;

import star from "../../assets/img/star.jfif";
import moment from "moment";

const data = {
  star_img: star,
  meeting_name: "Meeting Name",
  datetime: "13th Jan 2021 - 19:30",
};

export default function Card(props) {
  const places = Object.values(props.meeting.MeetingPlace);
  // console.log("What I have in the card: ", props, props.meeting.MeetingPlace);

  return (
    <div
      className="card"
      onClick={() => props.setDetailedMeeting(props.meeting.MeetingID)}
    >
      <div className="card__top">
        <img
          src={
            places[0].place_image_link
              ? places[0].place_image_link
              : "https://picsum.photos/200/300/?blur"
          }
          alt={""}
        />
        {/* <div className="rating__pan">
          <img
            className="rating__pan__star__img"
            src={data.star_img}
            alt="star"
          />
          <span>
            {props.rating} | {props.user_ratings_total}
          </span>
        </div> */}
      </div>
      <div className="bottom__banner">
        <h3 className="card__meetingName">{props.meeting.MeetingTitle}</h3>
        <h4 className="card__date__time">
          {moment(new Date(props.meeting.MeetingTime)).format(
            "DD.MM.YYYY HH:mm"
          )}
        </h4>
        <div className="card__location">
          {/* <img
            className="location__icon"
            src={props.icon}
            width="20"
            height="20"
            alt="icon"
          /> */}
          <div className="location__name">
            {places.length === 1 && places[0].place_type != "cardMap"
              ? places[0].place_title
              : places[0].location_address}
          </div>
        </div>
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  FCMToken: '',
};

export const getFCMTokenSlice = createSlice({
  name: 'getFCMToken',
  initialState,
  reducers: {
    setFCMToken: (state, action) => {
      state.FCMToken = action.payload 
    },
  },
});

export const { setFCMToken } = getFCMTokenSlice.actions
export default getFCMTokenSlice.reducer;

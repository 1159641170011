import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/localStorage";
import { getWSService } from "../../utils/webSocket";
import { store } from "../store";

const initialState = {
  meetings: {},
  invitations: {},
};

export const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {},
  extraReducers: {
    // function to get rid of all user data
    LogoutMeetingReset: (state, action) => {
      Storage.clearData();
      console.log(
        "Loged User out and deleted all meetings from store. [Redux-Store]"
      );
      return {
        meetings: {},
        invitations: {},
      };
    },
    //----------------------------- what happens after meeting creation --------------------------------
    CreateNewMeetingSuccess: (state, action) => {
      console.log("I successfully created a new meeting: ", action.payload);

      let NewMeeting = {
        MeetingID: action.payload.meeting_id,
        CreationTime: action.payload.creation_time,
        TopicArn: action.payload.topic_arn,
        UserArn: action.payload.user_arn,
        UserID: action.payload.user_id,

        MeetingTitle: action.payload.meeting_title,
        MeetingDescription: action.payload.meeting_description,
        MeetingTime: action.payload.meeting_time,
        MeetingEnd: action.payload.meeting_end_date,
        MeetingAgenda: action.payload.agenda,
        MeetingPlace: action.payload.meeting_place,
        MeetingType: action.payload.meeting_type,
        Participants: {},
        UserName: action.payload.name,
        OrganizerID: action.payload.user_id,
      };
      console.log("I created this new meeting: ", NewMeeting);

      // updateWebSocketNewMeetingInvitation(action.payload.meeting_id);

      if (action.payload.meeting_id) {
        let newMeetings = { ...state.meetings };
        newMeetings[action.payload.meeting_id] = NewMeeting;

        Storage.setJson("@meetings", newMeetings); // only need to overwrite what is in the storage
        console.log("Loaded new meeting into Store. [Redux-Store]");
        return {
          ...state,
          meetings: newMeetings,
        };
      }
    },
    MeetingLoader: (state, action) => {
      console.log("Loaded the meetings into Store. [Redux-Store]");
      console.log("Updating the Connections...MeetingLoader...");

      // To avoid Error:
      // You may not call store.getState() while the reducer is executing.
      // setTimeout(async function () {
      //   updateWebSocketConnections();
      // }, 500);

      return {
        ...state,
        meetings: action.payload,
      };
    },
    UpdateUserSuccess: (state, action) => {
      console.log(
        "Loaded all the meetings for this User: ",
        action.payload.Items
      );
      let loadedMeetings = {};
      for (const meeting of action.payload.Items) {
        // this could also be done in the backend
        delete meeting.transport;
        delete meeting.joining_time;
        delete meeting.gps;
        delete meeting.user_arn;

        loadedMeetings[meeting.meeting_id] = meeting;
      }
      console.log("Loaded the meetings into Store. [Redux-Store]");
      return {
        ...state,
        meetings: loadedMeetings,
      };
    },
    // ------------------- What happens if the user joins a meeting --------------------
    JoinInviteSuccess: (state, action) => {
      try {
        console.log("This is the answer from the API: ", action.payload);
        let meetingID = action.payload.meeting_id;
        // --------------- meeting is deleted from the invitations
        let newInvitations = { ...state.invitations };
        let Meetings = { ...state.meetings };

        if (Meetings[meetingID] !== undefined) {
          Meetings[meetingID].archieved = false;
          //   Object.entries(Meetings[meetingID].MeetingPlace).length > 1
          //     ? action.payload.meeting_time === ""
          //       ? {}
          //       : (Meetings[meetingID].user_voted = "didVote")
          //     : {};
          console.log("Meeting Left. [Redux-Store]");
          Storage.setJson("@meetings", Meetings);

          // To avoid Error:
          // You may not call store.getState() while the reducer is executing.
          // updateWebSocketNewMeetingInvitation(meetingID);
        } else {
          try {
            console.log("those are the current invitations: ", newInvitations);
            let newMeeting = newInvitations[meetingID];
            delete newInvitations[meetingID];
            // --------------- new meeting object is created from the data---------------------
            console.log(
              "invitations with the last one deleted: ",
              newInvitations
            );
            // // --------------- new meeting is added to the meetings list --------------------
            let newMeetings = { ...state.meetings };
            newMeetings[meetingID] = newMeeting;
            console.log("all the new meetings that I have: ", newMeetings);
            console.log("Invitation transformed into meeting. [Redux-Store]");
            Storage.setJson("@meetings", newMeetings);

            // To avoid Error:
            // You may not call store.getState() while the reducer is executing.
            // updateWebSocketNewMeetingInvitation(meetingID);

            return {
              ...state,
              invitations: newInvitations,
              meetings: newMeetings,
            };
          } catch (error) {
            console.log("Joininvitesuccess", error);
          }
        }
      } catch (error) {
        console.log("Joininvitesuccess outer ", error);
      }
    },
    /** User Joins his own meeting */
    JoinMyMeetingSuccess: (state, action) => {
      let meetingInfo = action.payload;
      console.log("Joined Meeting Info: ", meetingInfo);

      // --------------- creating adding participants -------------------------------
      let Meetings = { ...state.meetings };
      let meetingID = meetingInfo.meeting_id;
      let Participants = {};
      // ---------------create new participant -------------------------------
      let newParticipant = {
        MeetingID: meetingInfo.meeting_id,
        CreationTime: meetingInfo.creation_time,
        TopicArn: meetingInfo.topic_arn,
        OrganizerID: meetingInfo.organizer_user_id,
        JoinerID: meetingInfo.user_id,
        JoinerArn: meetingInfo.user_arn,
        UserGPS: meetingInfo.gps,
        UserTransport: meetingInfo.transport,
        UserName: meetingInfo.name,
        ProfilePicture: meetingInfo.user_profile_picture,
        SubscriptionArn: meetingInfo.sub_arn,
      };
      Participants[meetingInfo.user_id] = newParticipant;
      // --------------- add participants to meeting --------------------------
      Meetings[meetingID].Participants = Participants;
      delete Meetings[meetingID].MeetingUpdateStatus;
      console.log("meeting with Participants: ", Meetings[meetingID]);

      // --------------- create the new state in store -------------------------
      console.log("Meeting Updated. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
      console.log("Invitation loaded into store. [Redux-Store]");
    },

    // ---------------- what happens if we got invited and fetched the meeting data ---------------
    GetInvitationSuccess: (state, action) => {
      let meetingInfo = action.payload.Items;
      console.log("Meeting Data: ", meetingInfo);

      if (meetingInfo.length > 0) {
        // console.log('Current pending invitations: ', newInvitations);
        // --------------- creating adding participants -------------------------------
        let meeting = {};
        let Participants = {};
        let Places = {};
        let Slots = {};
        meetingInfo.forEach(function (participant) {
          // ---------------create new participant -------------------------------
          let newParticipant = {
            MeetingID: participant.meeting_id,
            CreationTime: participant.creation_time,
            TopicArn: participant.topic_arn,
            OrganizerID: participant.organizer_user_id,

            JoinerID: participant.user_id,
            JoinerArn: participant.user_arn,
            UserGPS: participant.gps,
            UserTransport: participant.transport,
            UserName: participant.name,
            ProfilePicture: participant.user_profile_picture,
            SubscriptionArn: participant.sub_arn,
          };

          if (participant.n_status !== "LEFT") {
            Participants[participant.user_id] = newParticipant;
          }

          if (participant.hasOwnProperty("meeting_title")) {
            // --------------- creating new meeting invitation entry ----------------------
            let NewMeeting = {
              MeetingID: participant.meeting_id,
              CreationTime: participant.creation_time,
              TopicArn: participant.topic_arn,
              UserArn: participant.user_arn,
              UserID: participant.user_id,
              OrganizerID: participant.organizer_user_id,
              MeetingTitle: participant.meeting_title,
              MeetingDescription: participant.meeting_description,
              MeetingTime: participant.meeting_time,
              MeetingEnd: participant.meeting_end_date,
              MeetingAgenda: participant.agenda,
              MeetingType: participant.meeting_type,
              UserName: participant.name,
            };

            if (participant.user_id === participant.organizer_user_id) {
              Places = participant.meeting_place;
              Slots = participant.slots;
            }
            meeting = NewMeeting; // -> might need to sort first
            console.log("New Meeting Data: ", meeting);
          }
        });
        // --------------- add participants to meeting --------------------------
        meeting.Participants = Participants;
        meeting.MeetingPlace = Places;
        meeting.Slots = Slots;
        console.log("meeting with Participants: ", meeting);
        console.log("meeting ID from the same: ", meeting.MeetingID);
        // --------------- add new meeting to invitations -----------------------
        let newInvitations = { ...state.invitations };
        newInvitations[meeting.MeetingID] = meeting;

        console.log("those are the invitations that I have: ", newInvitations);
        // --------------- create the new state in store -------------------------
        console.log("Invitation loaded into store. [Redux-Store]");
        Storage.setJson("@invitaions", newInvitations);
        return {
          ...state,
          invitations: newInvitations,
        };
      }

      console.log("Invitation loaded into store. [Redux-Store]");
      // return {status: 'success'};
    },
    UpdateMeetingSuccess: (state, action) => {
      try {
        let meetingInfo = action.payload.Items;
        console.log("Meeting Data: ", meetingInfo);

        if (meetingInfo.length > 0) {
          // console.log('Current pending invitations: ', newInvitations);
          // --------------- creating adding participants -------------------------------
          let Meetings = { ...state.meetings };
          let meetingID = meetingInfo[0].meeting_id;
          let Participants = {};
          let Places = {},
            MeetingTitle,
            StartDate,
            EndDate,
            Description,
            VoteResult,
            VoteCount,
            FinalizedPlaceId,
            DateVoteEnded,
            VoteEnded;
          meetingInfo.forEach(function (participant) {
            // ---------------create new participant -------------------------------
            let newParticipant = {
              MeetingID: participant.meeting_id,
              CreationTime: participant.creation_time,
              TopicArn: participant.topic_arn,
              OrganizerID: participant.organizer_user_id,

              JoinerID: participant.user_id,
              JoinerArn: participant.user_arn,
              UserGPS: participant.gps,
              UserTransport: participant.transport,
              UserName: participant.name,
              ProfilePicture: participant.user_profile_picture,
              SubscriptionArn: participant.sub_arn,
              ...(participant.date_user_voted && {
                DateUserVoted: participant.date_user_voted,
              }),
              ...(participant.user_voted && {
                UserVoted: participant.user_voted,
              }),
            };
            if (
              !isEmpty(participant.meeting_place) &&
              participant.organizer_user_id === participant.user_id
            ) {
              Places = participant.meeting_place;
              MeetingTitle = participant.meeting_title;
              StartDate = participant.meeting_time;
              EndDate = participant.meeting_end_date;
              Description = participant.meeting_description;
              VoteResult = participant.vote_result;
              VoteCount = participant.vote_count;
              FinalizedPlaceId = participant.finalized_place_id;

              if (participant.vote_ended) {
                VoteEnded = true;
              }
            }

            if (
              participant.organizer_user_id === participant.user_id &&
              participant.date_vote_ended
            ) {
              DateVoteEnded = true;
            }

            if (participant.n_status !== "LEFT") {
              Participants[participant.user_id] = newParticipant;
            }
          });
          // --------------- add participants to meeting --------------------------
          Meetings[meetingID].Participants = Participants;
          if (!isEmpty(Places)) {
            Meetings[meetingID].MeetingPlace = Places;
            Meetings[meetingID].MeetingTitle = MeetingTitle;
            Meetings[meetingID].MeetingTime = StartDate;
            Meetings[meetingID].MeetingEnd = EndDate;
            Meetings[meetingID].MeetingDescription = Description;
          }

          if (VoteResult) {
            let Votes = VoteResult;
            Object.entries(Votes).forEach(function (vote) {
              Meetings[meetingID].MeetingPlace[vote[0]].finalVote = vote[1];
            });
            Meetings[meetingID].status_voted = "finalVote";
            Meetings[meetingID].vote_count = VoteCount;

            const sorted = Object.values(
              Meetings[meetingID].MeetingPlace
            ).slice(0);
            sorted.sort(function (a, b) {
              return b.finalVote - a.finalVote;
            });

            let newPlacesObject = {};
            let voteResultObject = {};
            // get the highest vote
            // check for each place the vote
            // if it is the same as highest we put the place in the results list
            let highestVote = sorted[0].finalVote;
            sorted.forEach(function (place) {
              newPlacesObject[place.place_ID] = place;
              if (place.finalVote === highestVote) {
                voteResultObject[place.place_ID] = place;
              }
            });
            Meetings[meetingID].MeetingPlace = newPlacesObject;
            Meetings[meetingID].VoteResult = voteResultObject;
          }

          if (FinalizedPlaceId && FinalizedPlaceId !== "-") {
            Meetings[meetingID].FinalizedPlaceId = FinalizedPlaceId;
          }

          if (DateVoteEnded) {
            Meetings[meetingID].DateVoteEnded = true;
          }

          if (VoteEnded) {
            Meetings[meetingID].VoteEnded = true;
          }

          delete Meetings[meetingID].MeetingUpdateStatus;
          console.log("meeting with Participants: ", Meetings[meetingID]);

          // --------------- create the new state in store -------------------------
          console.log("Meeting Updated. [Redux-Store]");
          Storage.setJson("@meetings", Meetings);
        } else {
          let Meetings = { ...state.meetings };
          Meetings[action.payload.MeetingId].archieved = true;
          console.log("Meeting Deleted. [Redux-Store]");
          Storage.setJson("@meetings", Meetings);
        }
      } catch (error) {
        console.log(error);
      }
    },
    VoteSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from vote:",
        action.payload
      );
      let MeetingID = action.payload.meeting_id;
      let Votes = action.payload.votes;
      let vote_count = action.payload.vote_count;
      let Meetings = { ...state.meetings };
      console.log("--------------------------------");
      console.log("Meetings: ", Meetings);
      Object.entries(Votes).forEach(function (vote) {
        Meetings[MeetingID].MeetingPlace[vote[0]].ongoingVote = vote[1]; // vote[0] is the key vote[1] is vote
      });
      Meetings[MeetingID].status_voted = "ongoingVote";
      Meetings[MeetingID].user_voted = "didVote";
      Meetings[MeetingID].vote_count = vote_count;
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].MeetingPlace);

      const sorted = Object.values(Meetings[MeetingID].MeetingPlace).slice(0);
      sorted.sort(function (a, b) {
        return b.ongoingVote - a.ongoingVote;
      });

      let newPlacesObject = {};
      sorted.forEach(function (place) {
        newPlacesObject[place.place_ID] = place;
      });

      Meetings[MeetingID].MeetingPlace = newPlacesObject;

      Storage.setJson("@meetings", Meetings);
      // return {
      // 	...state,
      // 	meetings: Meetings,
      // };
    },
    EndVoteSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from EndVote:",
        action.payload
      );
      let MeetingID = action.payload.meeting_id;
      let Votes = action.payload.votes;
      let vote_count = action.payload.vote_count;
      let Meetings = { ...state.meetings };
      console.log("--------------------------------");
      console.log("Meetings: ", Meetings);
      Object.entries(Votes).forEach(function (vote) {
        Meetings[MeetingID].MeetingPlace[vote[0]].finalVote = vote[1];
      });
      Meetings[MeetingID].status_voted = "finalVote";
      Meetings[MeetingID].vote_count = vote_count;
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].MeetingPlace);

      const sorted = Object.values(Meetings[MeetingID].MeetingPlace).slice(0);
      sorted.sort(function (a, b) {
        return b.finalVote - a.finalVote;
      });

      let newPlacesObject = {};
      let voteResultObject = {};
      // get the highest vote
      // check for each place the vote
      // if it is the same as highest we put the place in the results list
      let highestVote = sorted[0].finalVote;
      sorted.forEach(function (place) {
        newPlacesObject[place.place_ID] = place;
        if (place.finalVote === highestVote) {
          voteResultObject[place.place_ID] = place;
        }
      });
      Meetings[MeetingID].MeetingPlace = newPlacesObject;
      Meetings[MeetingID].VoteResult = voteResultObject;

      Storage.setJson("@meetings", Meetings);
    },
    GetVotesSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from UpdateVote:",
        action.payload
      );
      // --------------- read the paylod into variables --------------------
      let MeetingID = action.payload.meeting_id;
      let vote_status = action.payload.status;
      let vote_count = action.payload.vote_count;
      let user_voted = action.payload.user_voted;
      let Votes = action.payload.votes;
      delete Votes["status"];
      delete Votes["meeting_id"];
      let Meetings = { ...state.meetings };

      // --------------- putting all the votes into places array and status into meeting object --------------------
      Object.entries(Votes).forEach(function (vote) {
        Meetings[MeetingID].MeetingPlace[vote[0]][vote_status] = vote[1];
      });
      Meetings[MeetingID].status_voted = vote_status;
      Meetings[MeetingID].vote_count = vote_count;
      if (user_voted === true) {
        Meetings[MeetingID].user_voted = "didVote";
      }
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].MeetingPlace);
      // ----------- sort the whole thing --------------------
      const sorted = Object.values(Meetings[MeetingID].MeetingPlace).slice(0);
      sorted.sort(function (a, b) {
        return b[vote_status] - a[vote_status];
      });

      let newPlacesObject = {};
      let voteResultObject = {};
      // get the highest vote
      // check for each place the vote
      // if it is the same as highest we put the place in the results list
      let highestVote = sorted[0].finalVote;
      sorted.forEach(function (place) {
        newPlacesObject[place.place_ID] = place;
        if (highestVote !== undefined && place.finalVote === highestVote) {
          voteResultObject[place.place_ID] = place;
        }
      });
      Meetings[MeetingID].MeetingPlace = newPlacesObject;
      Meetings[MeetingID].VoteResult = voteResultObject;

      //--------------- replace the current meeting object with the newly constructed --------------------------------
      Storage.setJson("@meetings", Meetings);
    },
    GetUserMeetingsSuccess: (state, action) => {
      console.log(
        "Loaded all the meetings for this User: ",
        action.payload.Items
      );
      let loadedMeetings = {};
      for (const item of action.payload.Items) {
        // this could also be done in the backend

        let NewMeeting = {
          MeetingID: item.meeting_id,
          CreationTime: item.creation_time,
          TopicArn: item.topic_arn,
          UserArn: item.user_arn,
          UserID: item.user_id,

          MeetingTitle: item.meeting_title,
          MeetingDescription: item.meeting_description,
          MeetingTime: item.meeting_time,
          MeetingEnd: item.meeting_end_date,
          MeetingAgenda: item.agenda,
          MeetingPlace: item.meeting_place,
          MeetingType: item.meeting_type,
          Participants: {},
          UserName: item.name,
          OrganizerID: item.organizer_user_id,

          MeetingUpdateStatus: "false",
          ...(item.n_status === "LEFT" && { archieved: true }),
        };

        if (item.slots) {
          NewMeeting["Slots"] = item.slots;
        }

        loadedMeetings[item.meeting_id] = NewMeeting;
      }
      console.log("Loaded this new objet: ", loadedMeetings);
      Storage.setJson("@meetings", loadedMeetings);
      console.log("Loaded the meetings into Store. [Redux-Store]");
      console.log("Updating the Connections...GetUserMeetingsSuccess...");

      // To avoid Error:
      // You may not call store.getState() while the reducer is executing.
      // setTimeout(async function () {
      //   updateWebSocketConnections();
      // }, 500);

      return {
        ...state,
        meetings: loadedMeetings,
      };
    },
    NewJoinNotification: (state, action) => {
      let msg = action.payload.mData;
      let meetings = JSON.parse(JSON.stringify(state.meetings));
      let meetingsObj = Object.keys(meetings).map((key) => [
        key,
        meetings[key],
      ]);
      let updatedMeetingObj = {};

      meetingsObj.forEach((meeting) => {
        if (meeting[1].MeetingID === msg.MeetingID) {
          meeting[1].Participants[msg.JoinerID] = msg;
          updatedMeetingObj = meeting[1];
        }
      });

      meetings[msg.MeetingID] = updatedMeetingObj;

      return {
        ...state,
        meetings: meetings,
      };
    },
    UpdateMeetingPlacesSuccess: (state, action) => {
      let meetingInfo = action.payload.Items;
      console.log("Meeting Places : ", meetingInfo);

      if (meetingInfo.length > 0) {
        // --------------- creating adding participants -------------------------------
        let Meetings = { ...state.meetings };
        let meetingID = meetingInfo[0].meeting_id;
        let Participants = {};
        let Places = {};
        meetingInfo.forEach(function (participant) {
          // ---------------create new participant -------------------------------
          let newParticipant = {
            MeetingID: participant.meeting_id,
            CreationTime: participant.creation_time,
            TopicArn: participant.topic_arn,
            OrganizerID: participant.organizer_user_id,
            JoinerID: participant.user_id,
            JoinerArn: participant.user_arn,
            UserGPS: participant.gps,
            UserTransport: participant.transport,
            UserName: participant.name,
            ProfilePicture: participant.user_profile_picture,
            SubscriptionArn: participant.sub_arn,
          };
          Participants[participant.user_id] = newParticipant;

          if (participant.organizer_user_id === participant.user_id) {
            Places = participant.meeting_place;
          }
        });
        // --------------- add participants to meeting --------------------------
        Meetings[meetingID].Participants = Participants;
        Meetings[meetingID].MeetingPlace = Places;
        delete Meetings[meetingID].MeetingUpdateStatus;
        console.log("meeting with Participants: ", Meetings[meetingID]);

        // --------------- create the new state in store -------------------------
        console.log("Meeting Updated. [Redux-Store]");
        Storage.setJson("@meetings", Meetings);
      }
    },
    UserLeftNotification: (state, action) => {
      let msg = action.payload.userLeftData;
      let Meetings = { ...state.meetings };
      delete Meetings[msg.MeetingID].Participants[msg.JoinerID];
      Storage.setJson("@meetings", Meetings);
    },
    LeaveMeetingSuccess: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      Meetings[meetingInfo.meeting_id].archieved = true;

      console.log("Meeting Left. [Redux-Store]");
      // Storage.setJson("@meetings", Meetings);
    },
    DeleteMeetingSuccess: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      meetingInfo.meetingCancelledData !== undefined
        ? (Meetings[
            meetingInfo.meetingCancelledData.meeting_id
          ].archieved = true)
        : (Meetings[meetingInfo.meeting_id].archieved = true);
      console.log("Meeting Deleted. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
    },
    UnArchieveMeeting: (state, action) => {
      let Meetings = { ...state.meetings };
      if (Meetings[action.payload] !== undefined) {
        Meetings[action.payload].archieved = false;
        console.log("Meeting Left. [Redux-Store]");
        Storage.setJson("@meetings", Meetings);
      }
    },
    DrawResultMeeting: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      if (meetingInfo.drawResultData.is_organizer === false) {
        Meetings[meetingInfo.drawResultData.meeting_id].status_voted =
          "ongoingVote";
      }
      console.log("Meeting Draw Resolved. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
    },
    UpdateMeetingDetailsSuccess: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      let meetingID = meetingInfo.meeting_id;
      Meetings[meetingID].MeetingPlace = meetingInfo.meeting_place;
      Meetings[meetingID].MeetingTime = meetingInfo.start_date;
      Meetings[meetingID].MeetingEnd = meetingInfo.end_date;
      Meetings[meetingID].MeetingDescription = meetingInfo.description;
      Meetings[meetingID].MeetingTitle = meetingInfo.meeting_title;
      Meetings[meetingID].MeetingUpdateTime = meetingInfo.update_time;
      Meetings[meetingID].FinalizedPlaceId = meetingInfo.finalized_place_id;
      console.log("Meeting Updated. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
    },
    UpdateMeetingSlotsSuccess: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      let meetingID = meetingInfo.meeting_id;
      Meetings[meetingID].Slots = meetingInfo.slots;
      Meetings[meetingID].MeetingUpdateTime = meetingInfo.update_time;
      console.log("Meeting Slots Updated. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
    },
    DateVoteSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from date vote:",
        action.payload
      );
      let MeetingID = action.payload.meeting_id;
      let DateVotes = action.payload.votes;
      let date_vote_count = action.payload.vote_count;
      let Meetings = { ...state.meetings };
      console.log("--------------------------------");
      console.log("Meetings: ", Meetings);
      Object.entries(DateVotes).forEach(function (vote) {
        Meetings[MeetingID].Slots[vote[0]].ongoingVote = vote[1]; // vote[0] is the key vote[1] is vote
      });
      Meetings[MeetingID].date_status_voted = "ongoingVote";
      Meetings[MeetingID].date_user_voted = "didVote";
      Meetings[MeetingID].date_vote_count = date_vote_count;
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].Slots);

      const sorted = Object.values(Meetings[MeetingID].Slots).slice(0);
      sorted.sort(function (a, b) {
        return b.ongoingVote - a.ongoingVote;
      });

      let newDatesObject = {};
      sorted.forEach(function (vote) {
        newDatesObject[vote.date_ID] = vote;
      });

      Meetings[MeetingID].Slots = newDatesObject;

      Storage.setJson("@meetings", Meetings);
    },
    EndDateVoteSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from EndVote:",
        action.payload
      );
      let MeetingID = action.payload.meeting_id;
      let Votes = action.payload.votes;
      let vote_count = action.payload.vote_count;
      let Meetings = { ...state.meetings };
      console.log("--------------------------------");
      console.log("Meetings: ", Meetings);
      Object.entries(Votes).forEach(function (vote) {
        Meetings[MeetingID].Slots[vote[0]].finalVote = vote[1];
      });
      Meetings[MeetingID].date_status_voted = "finalVote";
      Meetings[MeetingID].date_vote_count = vote_count;
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].Slots);

      const sorted = Object.values(Meetings[MeetingID].Slots).slice(0);
      sorted.sort(function (a, b) {
        return b.finalVote - a.finalVote;
      });

      let newDatesObject = {};
      let voteResultObject = {};
      // get the highest date
      // check for each date the vote
      // if it is the same as highest we put the date in the results list
      let highestVote = sorted[0].finalVote;
      sorted.forEach(function (date) {
        newDatesObject[date.date_ID] = date;
        if (date.finalVote === highestVote) {
          voteResultObject[date.date_ID] = date;
        }
      });
      Meetings[MeetingID].Slots = newDatesObject;
      Meetings[MeetingID].DateVoteResult = voteResultObject;

      Storage.setJson("@meetings", Meetings);
    },
    GetDateVotesSuccess: (state, action) => {
      console.log(
        "-------------------------------- Action Payload from UpdateVote:",
        action.payload
      );
      // --------------- read the paylod into variables --------------------
      let MeetingID = action.payload.meeting_id;
      let vote_status = action.payload.status;
      let vote_count = action.payload.vote_count;
      let user_voted = action.payload.user_voted;
      let Votes = action.payload.votes;
      delete Votes["status"];
      delete Votes["meeting_id"];
      let Meetings = { ...state.meetings };

      // --------------- putting all the votes into places array and status into meeting object --------------------
      Object.entries(Votes).forEach(function (vote) {
        Meetings[MeetingID].Slots[vote[0]][vote_status] = vote[1];
      });
      Meetings[MeetingID].date_status_voted = vote_status;
      Meetings[MeetingID].date_vote_count = vote_count;
      if (user_voted === true) {
        Meetings[MeetingID].user_voted = "didVote";
      }
      console.log("--------------------------------");
      console.log(Meetings[MeetingID].Slots);
      // ----------- sort the whole thing --------------------
      const sorted = Object.values(Meetings[MeetingID].Slots).slice(0);
      sorted.sort(function (a, b) {
        return b[vote_status] - a[vote_status];
      });

      let newDatesObject = {};
      let voteResultObject = {};
      // get the highest vote
      // check for each place the vote
      // if it is the same as highest we put the place in the results list
      let highestVote = sorted[0].finalVote;
      sorted.forEach(function (date) {
        newDatesObject[date.date_ID] = date;
        if (highestVote !== undefined && date.finalVote === highestVote) {
          voteResultObject[date.date_ID] = date;
        }
      });
      Meetings[MeetingID].Slots = newDatesObject;
      Meetings[MeetingID].DateVoteResult = voteResultObject;

      //--------------- replace the current meeting object with the newly constructed --------------------------------
      Storage.setJson("@meetings", Meetings);
    },
    UpdateGpsSuccess: (state, action) => {
      let meetingInfo = action.payload.gpsUpdateData
        ? action.payload.gpsUpdateData
        : action.payload;
      let Meetings = { ...state.meetings };
      let meetingID = meetingInfo.meeting_id;
      Meetings[meetingID].Participants[meetingInfo.user_id].UserGPS =
        meetingInfo.gps;
      Storage.setJson("@meetings", Meetings);
    },
    DateDrawResultMeeting: (state, action) => {
      let meetingInfo = action.payload;
      let Meetings = { ...state.meetings };
      if (meetingInfo.dateDrawResultData.is_organizer === false) {
        Meetings[meetingInfo.dateDrawResultData.meeting_id].date_status_voted =
          "ongoingVote";
      }
      console.log("Meeting Draw Resolved. [Redux-Store]");
      Storage.setJson("@meetings", Meetings);
    },
  },
});

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function updateWebSocketConnections() {
  console.log("----initiate mapping of meetings and connections------");
  getWSService().sendMessage("meetings", {}, false, false);
}

function updateWebSocketNewMeetingInvitation(meetingID) {
  console.log(
    "----initiate mapping of newly joined / created meeting connection------"
  );
  setTimeout(async function () {
    Storage.get("@UserArn")
      .then((userArn) => {
        Storage.get("@UserID")
          .then((uId) => {
            let meetingObjs = [];
            meetingObjs.push({ m_id: meetingID, u_arn: userArn, u_id: uId });
            let message = {};
            message.routeKey = "meetings";
            message.message = meetingObjs;
            store.dispatch({
              type: "StoreOfflineMessage",
              payload: {
                message: message,
              },
            });
            setTimeout(async function () {
              store.dispatch({
                type: "SendOfflineMessages",
                payload: {},
              });
            }, 500);
          })
          .catch((err) => {
            console.log("could not load UserID : ", err);
          });
      })
      .catch((err) => {
        console.log("could not load UserArn : ", err);
      });
  }, 500);
}

export const meetings = (state) => state.meetings;
export default meetingsSlice.reducer;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ThreeDots } from "react-loader-spinner";
import { store } from "../../store/services";
import Network from "../../store/services";
import { GetUserData } from "../../store/actions/actionConstants";
import { GetUserMeetings } from "../../store/actions/actionConstants";

import Input from "./Input";
import Checkbox from "./Checkbox";
import SocialButton from "./SocialButton";
import Button from "../buttons/Button";
import "./loginForm.scss";
import { ReactComponent as LogoLight } from "../../assets/logo/logo-light.svg";
import { ReactComponent as MeetUsIcon } from "../../assets/icons/meetus-app-icon.svg";
import { ReactComponent as GoogleLogo } from "../../assets/icons/google-g-logo.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook-f-logo.svg";
import { useNavigate } from "react-router-dom";

import signUp from "../../utils/auth/signUp";

import authUser from "../../utils/auth/authUser";
import getCredentials from "../../utils/auth/getCredentials";

export default function SignUpForm(props) {
  // styling submit button based on form input
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [emailInput, setEmailInput] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [nickName, setNickName] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [staySigned, setStaySigned] = useState(false);

  const [loading, setLoading] = useState(false);

  function detectFirstNameInput(e) {
    setFirstName(e.target.value.toLowerCase());
  }
  function detectLastNameInput(e) {
    setLastName(e.target.value.toLowerCase());
  }
  function detectNickNameInput(e) {
    setNickName(e.target.value.toLowerCase());
  }
  function detectEmailInput(e) {
    setEmailInput(e.target.value.toLowerCase());
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value.toLowerCase());
  }
  function detectConfirmPasswordInput(e) {
    setConfirmPasswordInput(e.target.value.toLowerCase());
  }
  function handleTermsCheck() {
    setAgreeToTerms(!agreeToTerms);
  }
  function handleStaySignedCheck() {
    setStaySigned(!staySigned);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasWhiteSpace(s) {
    return s.indexOf(" ");
  }
  async function checkInput() {
    if (!emailInput || emailInput === "") {
      return false;
    }
    if (hasWhiteSpace(emailInput) >= 0) {
      return false;
    }
    if (!lastName || lastName === "") {
      return false;
    }
    if (hasWhiteSpace(lastName) >= 0) {
      return false;
    }
    if (!firstName || firstName === "") {
      return false;
    }
    if (hasWhiteSpace(firstName) >= 0) {
      return false;
    }
    if (!nickName || nickName === "") {
      return false;
    }
    if (hasWhiteSpace(nickName) >= 0) {
      return false;
    }
    const checkMail = validateEmail(emailInput);
    if (!checkMail) {
      return false;
    }

    if (!(passwordInput.length > 0)) {
      return false;
    }
    if (hasWhiteSpace(passwordInput) >= 0) {
      return false;
    }
    if (agreeToTerms != true) {
      return false;
    }
    if (passwordInput != confirmPasswordInput) {
      return false;
    }

    return true;
  }

  async function signUpUser() {
    setLoading(true);
    let inputOk = await checkInput();
    // crashlytics().log('User-Signup API Call.');
    // await Promise.all([crashlytics().setUserId(email)]);
    if (inputOk) {
      let gender = "male";

      signUp(passwordInput, firstName, lastName, emailInput, gender)
        .then(() => {
          return dispatch({
            type: "SignUpUserSuccess",
            payload: {
              firstName: firstName,
              familyName: lastName,
              email: emailInput,
              password: passwordInput,
              nickName: nickName,
              confirmProcess: "signup",
            },
          });
        })
        .then((response) => {
          console.log("This here is the response: ", response);
          // analytics().logEvent('signup');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          // crashlytics().recordError(error);
        });
    } else {
      console.log("something wrong with input");
      setLoading(false);
    }
  }

  useEffect(() => {
    // navigate("/meetings");
    console.log("running conditional");
    console.log("emailInput", emailInput);
    console.log("passwordInput", passwordInput);
    if (emailInput !== "" && passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg100");
    } else if (emailInput !== "" || passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    } else {
      document.getElementById("loginSubmit").classList.remove("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    }
  });

  function googleClick() {
    alert("clicked Google Button!");
  }
  function facebookClick() {
    alert("clicked Facebook Button!");
  }
  return (
    <div className="loginFormContainer">
      <LogoLight className="logo--light" />
      <h2>Sign up for meetUs</h2>
      <p>For the web use you need to sign up</p>

      <form id="loginForm">
        <Input
          type="name"
          inputId="firstName"
          required="required"
          labelId="firstName"
          labelString="FIRST NAME"
          detectInput={detectFirstNameInput}
        />
        <Input
          type="name"
          inputId="familyName"
          required="required"
          labelId="familyName"
          labelString="LAST NAME"
          detectInput={detectLastNameInput}
        />
        <Input
          type="name"
          inputId="nickName"
          required="required"
          labelId="nickName"
          labelString="NICK NAME"
          detectInput={detectNickNameInput}
        />
        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
        <Input
          type="password"
          inputId="signup_password"
          required="required"
          labelId="signupPasswordLabel"
          labelString="PASSWORD"
          detectInput={detectPasswordInput}
        />
        <Input
          type="password"
          inputId="confirm_password"
          required="required"
          labelId="confirmPasswordLabel"
          labelString="CONFIRM PASSWORD"
          detectInput={detectConfirmPasswordInput}
        />
        <div className="checkboxContainer">
          <Checkbox
            labelText="Agree to Terms"
            onChange={handleTermsCheck}
            checked={agreeToTerms}
          />
        </div>
      </form>
      {/* <div className="socialContainer">
        <SocialButton
          icon={<GoogleLogo />}
          buttonClick={googleClick}
          className="google"
          border="1px solid #F2F2F2"
        />
        <SocialButton
          icon={<FacebookLogo />}
          buttonClick={facebookClick}
          className="facebook"
          border="none"
        />
        <SocialButton
          icon={'Guest'}
          buttonClick={props.getToken}
          className="guest"
          border="none"
        />
      </div> */}
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ED3424"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={loading}
      />
      {loading ? null : (
        <Button
          buttonClick={() => signUpUser()}
          width={180}
          className={"confirm"}
          name={"confirm"}
        />
      )}
      <div className="checkboxContainer">
        <Checkbox
          labelText="Stay signed in"
          onChange={handleStaySignedCheck}
          checked={staySigned}
        />
      </div>

      <button type="submit" form="loginForm" id="loginSubmit">
        <MeetUsIcon />
      </button>
      <a href="#test">Can't sign in?</a>
      <a href="/">Login</a>
    </div>
  );
}

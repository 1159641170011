import axios from "axios";
import { store } from "../store/store";

// import { store } from "../store/services";
import { GlobalAuth } from "../store/actions/actionConstants";
import { SignHeaders } from "../store/services/network";

const customAxios = axios.create();

customAxios.interceptors.request.use((res) => {
  console.log("I am here intercepting the Request: ");
  console.log(JSON.stringify(res, null, 2));
  // Important: response interceptors **must** return the response.
  return res;
});

customAxios.interceptors.response.use(
  function (data) {
    return data;
  },
  async function (err) {
    console.log("API:  ", err.config.url.replace("https://api.meetus.app", ""));
    console.log("Method: ", err.config.method);
    // originalRequest would be err.config

    const originalRequest = err.config;
    console.log("Original Request: ", originalRequest);

    if (err.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      return store
        .dispatch(store.networkCall({ action: GlobalAuth, data: {} }))
        .then((response) => {
          // console.log('Dispatch response: ', response);

          let api = err.config.url;
          api = api.replace("https://api.meetus.app", "");
          if (err.config.method === "get" || err.config.method === "GET") {
            return SignHeaders(
              { api: api, method: err.config.method },
              { data: {} }
            );
          }
          return SignHeaders(
            // {api: err.config.url.slice(22), method: err.config.method},
            { api: api, method: err.config.method },
            JSON.parse(err.config.data)
          );
        })
        .then((response) => {
          // exchange headers with new headers
          originalRequest.headers = response.headers;
          // retry original request with new signature and headers
          // return customAxios(response.url, response, response.data); // old working code

          // console.log(JSON.stringify(originalRequest, null, 2));
          return customAxios(originalRequest);
        })
        .catch((err) => {
          console.log("Could not make the call again", err);
        });
    }
    console.log("Got the Status: ", err.response.status);
    return err;
  }
);

export default customAxios;

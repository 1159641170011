import { NavLink } from 'react-router-dom';

export default function MenuItem(props) {
  return (
    <li className="option" onClick={props.closeMobileMenu}>
      {/* <a href="#">ABOUT</a> */}
      <NavLink
        exact={true}
        to={props.to}
        className="navItem"
        activeClassName="navItem--active"
      >
        {/* {props.icon} */}
        <span>{props.name}</span>
      </NavLink>
    </li>
  );
}

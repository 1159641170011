import { motion } from 'framer-motion';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    display: 'flex',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    display: 'none',
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export default function DropdownItem(props) {
  return (
    <motion.li
      variants={variants}
      onClick={() => {
        props.onClick(props.item);
        props.cycleIsOpen();
      }}
      className={String(props.activeItem) === props.name ? 'active' : ''}
    >
      <div>{props.name}</div>
    </motion.li>
  );
}

import { NavLink } from 'react-router-dom';

export default function NavItem(props) {
  return (
    <NavLink
      exact={true}
      to={props.to}
      className="navItem"
      activeClassName="navItem--active"
    >
      {/* {props.icon} */}
      <span>{props.name}</span>
    </NavLink>
  );
}

import { Scrollbars } from "react-custom-scrollbars";
import jsondata from "../../logic/testData/getPlaces";
import "./cards.scss";
import Card from "./Card";
import Button from "../buttons/Button";

import { ReactComponent as MeetUsAppIcon } from "../../assets/icons/meetus-app-icon.svg";

export default function MeetingCardContainer(props) {
  console.log(props.showMeeting);
  return (
    <Scrollbars thumbSize={200}>
      <div className="card__scrollbarCont">
        {props.showMeeting?.length > 0 ? (
          props.showMeeting.map((meeting, index) => {
            // jsondata.body[0].places.map((bodyData, index) => {
            return (
              <Card
                key={index}
                meeting={meeting}
                setDetailedMeeting={props.setDetailedMeeting}
                // photos={meeting.photos}
                // name={meeting.name}
                // rating={meeting.place_rating}
                // user_ratings_total={meeting.user_ratings_total}
                // icon={meeting.icon}
              />
            );
          })
        ) : (
          <di className="no_meeting"> No Meeting on this day</di>
        )}
        <div className="create__meeting__btn">
          <Button
            name={"Create Meeting"}
            width="13rem"
            icon={<MeetUsAppIcon className="button__icon" />}
            buttonClick={props.toggleMeetingModal}
          />
        </div>
      </div>
    </Scrollbars>
  );
}

import "./button.scss";

export default function Button(props) {
  return (
    <button
      onClick={() => {
        props.buttonClick();
      }}
      className={"button " + props.className}
      style={{ width: props.width }}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.icon}
      <span>{props.name}</span>
    </button>
  );
}

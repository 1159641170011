import { useState } from "react";
import "./calenderpicker.scss";

import { DayPicker } from "react-day-picker";
import moment from "moment";

export default function CalendarPicker(props) {
  let footer = <p>Please pick a day.</p>;
  if (props.selected) {
    footer = (
      <p>You picked {moment(new Date(props.selected)).format("DD.MM.YYYY")}.</p>
    );
  }

  return (
    <DayPicker
      mode="single"
      selected={props.selected}
      onSelect={props.setSelected}
      footer={footer}
      numberOfMonths={1}
      showOutsideDays
      showWeekNumber
      fromDate={new Date()}

      //   styles={{
      //     caption: { color: "red", fontWeight: "bold" },

      //     day: { borderWidth: 0, backgroundColor: "transparent" },
      //     today: { backgroundColor: "red", color: "red", fontWeight: "bold" },
      //     navButtonPrev: { borderWidth: 0, backgroundColor: "transparent" },
      //     navButtonNext: { borderWidth: 0, backgroundColor: "transparent" },
      //   }}
    />
  );
}

import Checkbox from "../forms/Checkbox";
import MapAutocomplete from "./MapAutocomplete";
import CardPlace from "../cards/CardPlace";
import Button from "../buttons/Button";

import "./changePlace.scss";

export default function ChangePlace(props) {
  return (
    <>
      <div className="findPlaces">
        <div className="addedPlaces">
          {Object.values(props.meetingPlace).length > 0 ? (
            <div>
              {Object.values(props.meetingPlace).map((place) => {
                return (
                  <CardPlace
                    place={place}
                    key={place}
                    meetingPlace={props.meetingPlace}
                    setMeetingPlace={props.setMeetingPlace}
                  />
                );
              })}
            </div>
          ) : (
            <div className="noPlaces">
              <span>I have NO places</span>
            </div>
          )}
        </div>

        <div className="mapContainer">
          <MapAutocomplete
            meetingPlace={props.meetingPlace}
            setMeetingPlace={props.setMeetingPlace}
          />
        </div>
      </div>
      <Button buttonClick={() => props.setChangePlace(false)} name={"Done"} />
    </>
  );
}

import {createSlice} from '@reduxjs/toolkit';
import Storage from '../../utils/localStorage';

const initialState = {
	currentTheme: 'light',
	preferredLanguage: '',
};

export const setUserPreferences = createSlice({
	name: 'userPreferences',
	initialState,
	reducers: {
		setCurrentTheme: (state, action) => {
			state.currentTheme = action.payload;
			Storage.set('@uiTheme', action.payload);
			console.log('Stored current UITheme. [Redux-Store]: ', action.payload);
		},
		setPreferredLanguage: (state, action) => {
			state.preferredLanguage = action.payload;
			Storage.set('@preferredLanguage', action.payload);
			console.log('Stored preferred Language. [Redux-Store]', action.payload);
		},
	},
});

export const {setCurrentTheme, setPreferredLanguage} =
	setUserPreferences.actions;
export default setUserPreferences.reducer;
